import React, { useEffect, useState, useParams } from 'react';
import { useAlert } from 'react-alert'
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import HeaderScreen from '../components/HeaderScreen'
import ScreenLoading from '../components/ScreenLoading'
import M from 'materialize-css';
import { DatePicker, Autocomplete } from "react-materialize";
import { format } from "date-fns";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';

const EditProfile = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const alert = useAlert();
  const history = useHistory();
  const [spinnerScreen, setSpinnerScreen] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [spinnerImg, setSpinnerImg] = useState(false);
  const [editAddress, setEditAddress] = useState(false);

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [gender, setGender] = useState('');
  const [birthday, setBirthday] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [address, setAddress] = useState('');
  const [addressCode, setAddressCode] = useState('');
  const [fullAddress, setFullAddress] = useState('');
  const [imageSrc, setImageSrc] = useState();
  const [instagram, setInstagram] = useState('');
  const [linkMarketplace1, setLinkMarketplace1] = useState('');

  const [queryDistrict, setQueryDistrict] = useState('');
  const [districts, setDistricts] = useState([]);

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    
    const files = e.target.files[0]
    const fd = new FormData();
    fd.append('avatar', files, files.name);

    setSpinnerImg(true);
    const config = {  headers: { Authorization: `Bearer ${token}` } };

    API.put(`users/${currentUser.id}/avatar`, fd, config )
      .then(res => {
        const data = res.data.data;
        ReactSession.set("current_user", data);
        setSpinnerImg(false);
        alert.success('Avatar Berhasil Diubah');
      })
      .catch(error => {
        setSpinnerImg(false);
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const handleOnSearch = (string, results) => {
    console.log(string, results)
    findDistrict(string)
  }

  const handleOnHover = (result) => {
    console.log(result)
  }

  const handleOnSelect = (item) => {
    setAddressCode(item.id)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return item;
   // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  }

  const findDistrict = (query) => {
    setQueryDistrict(query);
    console.log('find district '+query)
    API.get(`regions`, {params: {key: query}} )
      .then(res => {
        const data = res.data.data;
        setDistricts(data);
        console.log(data)
      })
  }

  const onChangeAddress = () => {
    editAddress === true ? setEditAddress(false) : setEditAddress(true)
  }

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`} };

    API.get(`sessions`, config )
      .then(res => {
        const data = res.data.data;
        setUsername(data.username);
        setEmail(data.email);
        setFullName(data.full_name);
        setGender(data.gender);
        setBirthday(data.birthday);
        setPhone1(data.phone1);
        setPhone2(data.phone2);
        setAddress(data.address);
        setAddressCode(data.address_code);
        setFullAddress(`${data.district}, ${data.city}, ${data.province}`)
        setDistricts([{id: data.address_code, name: data.district+','+data.city+','+data.province }])
        setInstagram(data.instagram);
        setLinkMarketplace1(data.link_marketplace1);
        setSpinnerScreen(false);
        setImageSrc(data.avatar_url)
        let elems = document.getElementById("sel");
        let instances = window.M.FormSelect.init(elems, {});
      })
      .catch(error => {
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const changeProfile = () => {
    setSpinner(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const data = {
      username: username,
      email: email,
      full_name: fullName,
      gender: gender,
      birthday: birthday,
      phone1: phone1,
      phone2: phone2,
      address: address,
      address_code: addressCode,
      instagram: instagram,
      link_marketplace1: linkMarketplace1
    }
    
    API.put(`user_app/${currentUser.id}`, data, config )
      .then(res => {
        const data = res.data.data;
        ReactSession.set("current_user", data);
        setFullAddress(`${data.district}, ${data.city}, ${data.province}`)
        setSpinner(false);
        alert.success('Profil Berhasil Diubah')
      })
      .catch(error => {
        setSpinner(false);
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/login")
    }
    
    loadData();

    // let elSide = document.querySelectorAll('.sidenav-overlay');
    // let elBody = document.querySelectorAll('body');
    // elSide.forEach((item: any) => {
    //   item.style.display = 'none';
    // })
    // elBody.forEach((item: any) => {
    //   item.style.overflow = 'auto';
    // })

    let elems = document.getElementById("sel");
    let instances = window.M.FormSelect.init(elems, {});

  }, []);

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed navbar-fixed-50">
        <nav className="nav-screen" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/profile" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Pengaturan Profil</span>
          </div>
        </nav>
      </div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <div className="row">
          <div className="m-top0">
            <div className="col s12">
              <div className='avatar-user'>
                <ImageUpload
                  handleImageSelect={handleImageSelect}
                  imageSrc={imageSrc}
                  setImageSrc={setImageSrc}
                  style={{
                    width: 120,
                    height: 120
                  }}
                />
                { spinnerImg ? (
                  <div className="col s12 m12" >
                    <center><Dots color='#444' /></center>
                  </div>
                ) : null }
              </div>
            </div>
            <div className="col s6">
              <label className="m-bottom10">Username</label>
              <input placeholder="Username" type="text" className="input-back" value={username} onChange={e => setUsername(e.target.value)} />
            </div>
            <div className="col s6">
              <label className="m-bottom10">Email</label>
              <input placeholder="Email" type="text" className="input-back" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="col s6">
              <label className="m-bottom10">Nama Lengkap</label>
              <input placeholder="Nama Lengkap" type="text" className="input-back" value={fullName} onChange={e => setFullName(e.target.value)} />
            </div>
            <div className="col s6 select-none-ui">
              <label className="m-bottom10">Jenis Kelamin</label>
              <select className="select" value={gender} onChange={e => setGender(e.target.value)} >
                <option value="Laki-Laki">Laki-Laki</option>
                <option value="Perempuan">Perempuan</option>
              </select>
            </div>
            <div className="col s12 datepicker-field">
              <label className="m-bottom10">Tanggal Lahir</label>
              <DatePicker options={{ yearRange: 70}} value={birthday} onChange={e => setBirthday( format(e, "yyyy-MM-dd") )}/>
            </div>
            <div className="col s6">
              <label className="m-bottom10">Nomor HP Utama</label>
              <input placeholder="Nomor Pribadi" type="text" className="input-back" value={phone1} onChange={e => setPhone1(e.target.value)} />
            </div>
            <div className="col s6">
              <label className="m-bottom10">Nomor HP Kedua</label>
              <input placeholder="Nomor Bisnis" type="text" className="input-back" value={phone2} onChange={e => setPhone2(e.target.value)} />
            </div>
            <div className="col s12 autocomplete-field">
              <label className="m-bottom10">Kecamatan Domisili</label>
              <a href='#' className='btn-right-label2' style={{position: 'relative'}} onClick={ onChangeAddress } ><i className="material-icons">edit</i> Ganti Kecamatan</a>
              { editAddress ? (
                <ReactSearchAutocomplete
                  items={districts}
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  // formatResult={formatResult}
                  />
              ) : (
                <div className='full-address-box'>
                  <p>{fullAddress}</p>
                </div>
              )}
            </div>
            <div className="col s12">
              <label className="m-bottom10">Alamat Lengkap</label>
              <input placeholder="Alamat Lengkap" type="text" className="input-back" value={address} onChange={e => setAddress(e.target.value)} />
            </div>
            <div className="col s6">
              <label className="m-bottom10">Nama Instagram</label>
              <input placeholder="@nama_ig" type="text" className="input-back" value={instagram} onChange={e => setInstagram(e.target.value)} />
            </div>
            <div className="col s6">
              <label className="m-bottom10">Link Marketplace</label>
              <input placeholder="https://shopee.com/nama_toko" type="text" className="input-back" value={linkMarketplace1} onChange={e => setLinkMarketplace1(e.target.value)} />
            </div>
            { spinner ? (
              <div className="col s12">
                <button className="btn waves-effect waves-light btn-large full-width" style={{background: (currentCompany.company_color3)}} type="submit" name="action" >
                  <center><Dots color='#fff' /></center>
                </button>
              </div>
            ) : (
              <div className="col s12">
                <button className="btn waves-effect waves-light btn-large full-width" style={{background: (currentCompany.company_color3)}} type="submit" name="action" onClick={changeProfile} >
                  Simpan Perubahan
                </button>
              </div>
            ) }
          </div>
        </div>
      )}
    </>
    ) : null
  )
}

export default EditProfile