import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Login from './page/Login';
import Register from './page/Register';
import Welcome from './page/Welcome';
import ForgotPassword from './page/ForgotPassword';
import Home from './page/Home';
import Dashboard from './page/Dashboard';
import EditPassword from './page/EditPassword';
import EditProfile from './page/EditProfile';
import EditPayment from './page/EditPayment';
import Products from './page/Products';
import ProductShow from './page/ProductShow';
import Carts from './page/Carts';
import OrderShow from './page/OrderShow';
import Orders from './page/Orders';
import Profile from './page/Profile';
import CheckShipping from './page/CheckShipping';
import TrackOrder from './page/TrackOrder';
import ContentKit from './page/ContentKit';
import Report from './page/Report';
import Blogs from './page/Blogs';
import BlogShow from './page/BlogShow';
import Rewards from './page/Rewards';
import RewardShow from './page/RewardShow';
import Payments from './page/Payments';
import HistoryMoneys from './page/HistoryMoneys';
import HistoryPoints from './page/HistoryPoints';
import Resellers from './page/Resellers';
import LandingPages from './page/LandingPages';
import RequestLandingPages from './page/RequestLandingPages';
import EditRequestLandingPage from './page/EditRequestLandingPage';
import Whistlists from './page/Whistlists';
import PrivacyPolicy from './page/PrivacyPolicy';

export const Routes = (
  <Switch>
    <Route path="/" exact component={Dashboard} />
    <Route path="/privacy_policy" exact component={PrivacyPolicy} />
    <Route path="/login" exact component={Login} />
    <Route path="/register" component={Register} />
    <Route path="/forgot_password" component={ForgotPassword} />
    <Route path="/welcome" component={Welcome} />
    <Route path="/home" component={Home} />
    <Route path="/profile" component={Profile} />
    <Route path="/dashboard" component={Dashboard} />
    <Route path="/edit_password" component={EditPassword} />
    <Route path="/edit_profile" component={EditProfile} />
    <Route path="/edit_payment" component={EditPayment} />
    <Route path="/products" component={Products} />
    <Route path="/product/:id" component={ProductShow} />
    <Route path="/carts" component={Carts} />
    <Route path="/orders/:id" component={OrderShow} />
    <Route path="/orders" component={Orders} />
    <Route path="/check_shipping" component={CheckShipping} />
    <Route path="/track_order" component={TrackOrder} />
    <Route path="/content_kit" component={ContentKit} />
    <Route path="/reports" component={Report} />
    <Route path="/blogs" component={Blogs} />
    <Route path="/blog/:id" component={BlogShow} />
    <Route path="/rewards" component={Rewards} />
    <Route path="/reward/:id" component={RewardShow} />
    <Route path="/payments" component={Payments} />
    <Route path="/history_moneys" component={HistoryMoneys} />
    <Route path="/history_points" component={HistoryPoints} />
    <Route path="/resellers" component={Resellers} />
    <Route path="/landing_pages" component={LandingPages} />
    <Route path="/request_landing_pages" component={RequestLandingPages} />
    <Route path="/edit_request_landing_page/:id" component={EditRequestLandingPage} />
    <Route path="/whistlists" component={Whistlists} />
  </Switch>
);