import React, { useEffect, useState} from 'react';
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';
import { useAlert } from 'react-alert';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const Welcome = () => {
  const {id} = useParams();
  const alert = useAlert();
  const history = useHistory();
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const [orderItems, setOrderItems] = useState([]);
  const [company, setCompany] = useState({});
  const [paymentNumbers, setPaymentNumbers] = useState([]);
  const [spinnerScreen, setSpinnerScreen] = useState(true);
  const [domain, setDomain] = useState('');
  const [user, setUser] = useState({});

  const loadData = () => {
    let hostName = window.location.hostname;
    hostName = hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    setDomain(hostName);

    const config = { params: {domain: hostName} };

    API.get(`get_company`, config )
      .then(res => {
        const data = res.data.data;
        setCompany(data);
      })
    
  }

  useEffect(() => {
    setUser(ReactSession.get("register_member"))
    loadData();
  },[])

  return (
    company ? (
      <>
        <div className="navbar-fixed navbar-fixed-50">
          <nav className="nav-screen" style={{ background: (company.themeplate === 'App Theme 2' ? company.company_color2 : '' ) }}>
            <div className="nav-wrapper">
              <ul className="left">
                <li>
                  <Link to="/" ><i className="material-icons" style={{color: (company.themeplate === 'App Theme 2' ? 'white' : company.company_color1 ) }}>keyboard_backspace</i></Link>
                </li>
              </ul>
              <span style={{color: (company.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Verifikasi Pendaftaran</span>
            </div>
          </nav>
        </div>

        { spinnerScreen === false ? (
          <ScreenLoading/>
        ) : user ? (
          <>
            <div className='sidenav-overlay'></div>
            <div className="row m-bottom0 back-white">
              <div className="">
                <div className="row border-bottom">
                  <div className="col s12 m12">
                    <div className='sub-order-total'> 
                      <i className="material-icons" style={{color: (company.company_color1)}}>check_circle</i>
                      <br/>
                      Selamat {user.full_name} !
                      <br/>
                      <span className='total'>
                        Akun Anda Berhasil Dibuat
                      </span>
                      <br/>
                      <span className='status-payment' style={styles.status_payment}>
                        Akun Customer
                      </span>
                      <br/>
                      <small>Waktu Pendaftaran {user.created_at}</small>
                    </div>
                  </div>
                </div>

                <div className="row border-bottom">
                  <div className="col s12 m12">
                    <div className='box-bank-number' style={{textAlign: 'left'}}> 
                      <small>
                        1. Akun Yang Anda Buat Adalah Akun Dengan Tingkatan Customer Maksudnya Adalah Akun Ini Hanya Bisa Digunakan Untuk Belanja Dengan Harga Customer Yang Bakal Di Dapat Nantinya.
                        <br/><br/>
                        2. Untuk <b>Upgrade Ke Akun Mitra</b> Yang Resmi Anda Harus Melakukan Pembelian Awal Sample Produk Terlebih Dahulu Lewat Admin, Sehingga Nantinya Anda Akan Dapat Harga Mitra Yang Jauh Lebih Murah Dari Harga Customer Tentunya. Untuk Upgrade Ke Akun Mitra Resmi Silahkan Hub Admin Dengan Klik Tombol Button Upgrade Akun Dibawah Detail Akun.
                      </small>
                    </div>
                  </div>
                </div>

                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s12 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      <b>DETAIL AKUN</b>
                    </div>
                  </div>
                </div>

                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      ID
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>{user.code}</b>
                    </div>  
                  </div>
                </div>
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      Jenis Akun
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>Customer</b>
                    </div>  
                  </div>
                </div>
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      Nama Lengkap
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>{user.full_name}</b>
                    </div>  
                  </div>
                </div>
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      Username
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>{user.username}</b>
                    </div>  
                  </div>
                </div>
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      No.HP/WA Aktif
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>{user.phone1}</b>
                    </div>  
                  </div>
                </div>
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      Alamat Email
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>{user.email}</b>
                    </div>  
                  </div>
                </div>
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      Tanggal Lahir
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>{user.birthday}</b>
                    </div>  
                  </div>
                </div>
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      Jenis Kelamin
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>{user.gender}</b>
                    </div>  
                  </div>
                </div>
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      Alamat Domisili
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>{user.city}, {user.province}</b>
                    </div>  
                  </div>
                </div>

                <div className="row border-bottom add-chart-seaction">
                  <div className="col s12 m12">
                    <a href={'https://api.whatsapp.com/send?phone='+company.phone1+'&text=Saya%20mau%20upgrade%20akun%20mitra%20dengan%20id%20'+user.code+'%20Nama%20'+user.full_name} className='btn' style={{background: (company.company_color2)}}>Konsultasi Upgrade Akun Mitra</a>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null }
      </>
    ) : null
  );
}

export default Welcome;

const styles: StyleSheet = {
  status: (status) => ({
    color: status === 'pending' ? 'orange' : (status === 'shipped' || status === 'received') ? 'green' : status === 'packing' ? '#11cdef' : 'red' ,
  }),
  status_payment: {
    fontSize: 11,
    color: 'orange',
    paddingBottom: 2,
    borderRadius: 5,
  }
}
