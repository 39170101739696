import React, { useEffect, useState} from 'react';
import { useAlert } from 'react-alert'
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import BottomSheet from '../components/BottomSheet';
import Countdown from 'react-countdown';
import LinesEllipsis from 'react-lines-ellipsis';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const Products = () => {
  const currentCompany = ReactSession.get("current_company");
  const alert = useAlert();
  const params = new URLSearchParams(window.location.search);
  const current_order_count = ReactSession.get("current_order_count");

  const token = ReactSession.get("token");
  const history = useHistory();

  const [all, setAll] = useState(true);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [subCategoryId, setSubCategoryId] = useState('');
  const [brandId, setBrandId] = useState('');
  const [promoId, setPromoId] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [showSidebarFilter, setShowSidebarFilter] = useState(false);

  const [galleries, setGalleries] = useState([]);
  const [galleriesPromo, setGalleriesPromo] = useState([]);

  const [promo, setPromo] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [promos, setPromos] = useState([]);
  const [spinner, setSpinner] = useState(true);

  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);

  const loadData = (keyword, category_id, brand_id, sub_category_id, promo_id) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'company, websetting_galleries'}};

    API.get(`sessions`, config )
      .then(res => {
        const data = res.data.data;
        setCurrentUser(data);

        if(data.company){
          ReactSession.set("current_company", data.company);

          if(data.company.websetting_galleries){
            setGalleries(data.company.websetting_galleries.filter((g) => g.category === 'home_app'));
            setGalleriesPromo(data.company.websetting_galleries.filter((g) => g.category === 'home_promo'));
          }

          var elem = document.querySelector(".slider2");
          var instance = window.M.Carousel.init(elem, {
            fullWidth: true,
            indicators: true,
          });
        }
      })
      .catch(error => {
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }
        console.log(error.response);
      })
    
    const config_product = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: currentCompany.id, key: keyword, by_position: 'updatest', by_category_id: category_id, by_sub_category_id: sub_category_id, by_brand_id: brand_id, by_promo_id: promo_id, include: 'product_prices, product_variants', by_status: true} };
    API.get(`products`, config_product)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setProducts(data)
        setSpinner(false)
        setLoadMore(false);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
    
    const config_opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: currentCompany.id, pagination: false, by_status: true, active_promo: true } };
    API.get(`categories`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCategories(data)
      })

    API.get(`promos`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setPromos(data)
      })

    API.get(`brands`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setBrands(data)
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { by_company_id: currentCompany.id, key: '', by_position: 'updatest', by_category_id: categoryId, by_sub_category_id: subCategoryId, by_brand_id: brandId, by_promo_id: promoId, page: offset_page, include: 'product_prices, product_variants', by_status: true } };
    
    API.get(`products`, config)
      .then(res => {
        setLoadMore(false);
        const data = res.data.data;
        setProducts([...products, ...data]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search, categoryId, brandId, subCategoryId, promoId);
  }

  const clearSearch = (search) => {
    setOffset(1);
    updateSearch('');
    loadData(search, '', '', '', '');
    setLoadMoreButton(false);
  }

  const searchCategory = (category_id) => {
    setCategoryId(category_id);
    setSubCategoryId('');
    setSpinner(true);
    setOffset(1);
    loadData(search, category_id, brandId, '', promoId);
    setLoadMoreButton(false);

    const config_opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: currentCompany.id, pagination: false, parent_id: category_id, by_status: true } };
    API.get(`categories`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setSubCategories(data)
      })
  }

  const searchSubCategory = (sub_category_id) => {
    setSubCategoryId(sub_category_id);
    setSpinner(true);
    setOffset(1);
    loadData(search, categoryId, brandId, sub_category_id, promoId);
    setLoadMoreButton(false);
  }

  const searchBrand = (brand_id) => {
    setAll(false);
    setPromoId('');
    setBrandId(brand_id);
    setSpinner(true);
    setOffset(1);
    loadData(search, categoryId, brand_id, subCategoryId, '');
    setLoadMoreButton(false);
  }

  const searchPromo = (promo_id) => {
    setAll(false);
    setBrandId('');
    setPromoId(promo_id);
    setSpinner(true);
    setOffset(1);
    loadData(search, categoryId, '', subCategoryId, promo_id);
    setLoadMoreButton(false);

    let _promo = promos.filter((p) => p.id === promo_id)
    if(_promo){
      setPromo(_promo[0]);
    }else{
      setPromo({});
    }
  }

  const deleteSearch = () =>{
    setSpinner(true);
    setSubCategoryId('');
    setCategoryId('');
    setBrandId('');
    setSearch('');
    loadData('', '', '', '', '');
  }

  const searchAll = () => {
    setAll(true);
    setPromoId('');
    setBrandId('');
    setSpinner(true);
    setOffset(1);
    loadData('', '', '', '', '');
    setLoadMoreButton(false);
  }

  const closeSearch = () =>{
    setShowSidebarFilter(false);
  }

  const showSearch = () =>{
    showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)
  }
  
  useEffect(() => {
    // if(token === undefined){
    //   history.push("/")
    // }

    setSearch(params.get('key'));
    loadData(params.get('key'), params.get('category'), brandId, subCategoryId, promoId);
    if(params.get('category')){
      setCategoryId(parseInt(params.get('category')))
      setAll(false)
    }
    M.AutoInit();

    let elSide = document.querySelectorAll('.sidenav-overlay');
    let elBody = document.querySelectorAll('body');
    elSide.forEach((item: any) => {
      item.style.display = 'none';
    })
    elBody.forEach((item: any) => {
      item.style.overflow = 'auto';
    })
    
  },[])

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed navbar-fixed-50">
        <nav className="nav-screen" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left" style={{width: '85%'}}>
              <li>
                <Link to="/dashboard" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
              <li style={{width: '78%'}}>
                <div className="input-field input-search-form full-width">
                  <input id="search" autocomplete="off" type="search" value={search} onChange={e => updateSearch(e.target.value)}/>
                  <label className="label-icon" for="search"><i className="material-icons material-icon-search" style={{color: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color1 : '' ) }}>search</i></label>
                </div>
              </li>
            </ul>
            <ul className="right" style={{width: 54}}>
              <li>
                <a onClick={showSearch}>
                  <i className="material-icons span-blue" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>sort</i>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      { showSidebarFilter ? (
        <div className='box-sidebar-filter'>
          <div className="sidebar-filter">
            <div className="sidebar-filter-head">
              Setting Pencarian
              <button className='sidebar-filter-close' onClick={closeSearch}>Keluar</button>
            </div>
            <div className="sidebar-filter-body">
              <div className="sidebar-filter-seaction row">
                <div className="col s12 m12" >
                  <div className='label-filter'>Cari Berdasarkan Kategori</div>
                  <div className="select-none-ui">
                    <select className="sel" value={categoryId} onChange={e => (searchCategory(e.target.value))} >
                      {
                        categories.filter(o => o.parent_id === null).map((c) => {
                          return (
                            <option key={c.id} value={c.id}>{c.name}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="sidebar-filter-seaction row">
                <div className="col s12 m12" >
                  <div className='label-filter'>Cari Berdasarkan Sub Kategori</div>
                  <div className="select-none-ui">
                    <select className="sel" value={subCategoryId} onChange={e => (searchSubCategory(e.target.value))} >
                      <option value=''>Semua Sub Kategori</option>
                      {
                        subCategories.map((c) => {
                          return (
                            <option key={c.id} value={c.id}>{c.name}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="sidebar-filter-seaction row">
                <div className="col s12 m12" >
                  <div className='label-filter'>Cari Berdasarkan Merek</div>
                  <div className="select-none-ui">
                    <select className="sel" value={brandId} onChange={e => (searchBrand(e.target.value))} >
                      <option value=''>Semua Merek</option>
                      {
                        brands.map((c) => {
                          return (
                            <option key={c.id} value={c.id}>{c.name}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="sidebar-filter-seaction row">
                <div className='row sidebar-filter-price'>
                  <div className='row'>
                    <div className="col s6 m6" >
                      <button className='btn btn-default btn-search-sidebar1' onClick={deleteSearch}>Hapus Pencarian</button>
                    </div>
                    <div className="col s6 m6" >
                      <button className='btn btn-search-sidebar2' style={{background: (currentCompany.company_color2)}} onClick={closeSearch}>Cari</button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="sidebar-filter-seaction">
                <div className='row sidebar-filter-price'>
                  <div className="col s5 m5 padding0" >
                    <div className='label-filter'>Min Harga</div>
                    <input type="number" onChange='' />
                  </div>
                  <div className="col s2 m2" >
                    <center className='p-top-15'>s/d</center>
                  </div>
                  <div className="col s5 m5 padding0" >
                    <div className='label-filter'>Max Harga</div>
                    <input type="number" onChange='' />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      ) : null }

      { brands || promos ? (
        <div className="row p-top-10 back-grey" style={{marginBottom: 0}}>
          <div className="col s12 m12" style={{padding: 0}}>
            <div className='scroll-touch'>
              <div className='scroll-container'>
                <div className={ all ? `icon-categories active` : 'icon-categories'} onClick={e => searchAll()}>
                  <div className="icon-image">
                    <i className="material-icons" style={{color: (currentCompany.company_color1)}}>thumb_up</i>
                  </div>
                  <div className='icon-category-name'>Semua</div>
                </div>
              
              
                {
                  promos.map((p) => {
                    return (
                      <div className={ promoId === p.id ? `icon-categories active` : 'icon-categories'} key={p.id} onClick={e => searchPromo(p.id)}>
                        <div className="icon-image">
                          <img src={p.image_url}/>
                        </div>
                        <div className='icon-category-name'>{p.title}</div>
                      </div>
                    )
                  })
                }

                {
                  brands.map((p) => {
                    return (
                      <div className={ brandId === p.id ? `icon-categories active` : 'icon-categories'}  key={p.id} onClick={e => searchBrand(p.id)}>
                        <div className="icon-image">
                          <img src={p.image_url}/>
                        </div>
                        <div className='icon-category-name'>{p.name}</div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      ) : null}

      { galleriesPromo && galleriesPromo.length > 0 ? (
        <div className="row m-bottom0" style={{padding: 5}}>
          <div className="slider2 carousel carousel-slider carousel-home" style={{borderRadius: 8}}>
            {galleriesPromo.map((d, i) => 
              <div key={i} className={i === 0 ? `carousel-item active` : `carousel-item`}><img src={d.image_url}/></div>
            )}
          </div>
        </div>
      ) : null }
      
      <div className="row m-top-10 m-bottom-0">
        { promoId ? (
          <>
            <div className="col s8 m8">
              <span className='title-card-content'>{promo.title}</span>
            </div>
            <div className="col s4 m4 text-right">
              <div className='countdown-txt'>
                <Countdown date={promo.end_date}/>
              </div>
            </div>
          </>
        ) : null }

        { spinner ? (
            <div className="col s12 m12" >
              <center><Dots color='#444' /></center>
            </div>
          ) : (
            <>
              { products && products.length > 0 ? (
                <>
                  {
                    products.map((p) => {
                      let price = p.sell_price;
                      let discount = 0;
                      if(p.product_type == 'price_on_variant' && p.product_variants && p.product_variants.filter(o => o.parent_id === null).length > 0){
                        let varian_sku = p.product_variants.filter(o => o.parent_id === null).sort((a, b) => a.id - b.id)[0]
                        if(p.product_prices.length > 0){
                          let pPrice = p.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser.group_user_id) && o.product_variant_id === varian_sku.id )[0]
                          if(pPrice !== undefined){
                            price = pPrice.sell_price;
                          }else{
                            price = varian_sku.sell_price;
                          }
                        }
                      }else{
                        let pPrice = p.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser.group_user_id) && o.product_variant_id === null )[0]
                        if(pPrice !== undefined){
                          price = pPrice.sell_price;
                        }
                      }

                      if(currentUser.group_user_id === null || currentUser.group_user_id === 0){
                        discount = parseInt(p.discount_customer)
                      }else{
                        discount = parseInt(p.discount)
                      }

                      return (
                        <div className="col s6 m6" key={p.id} style={{padding: 5}}>
                          <Link to={'/product/'+p.id} className="card card-product vertical" style={{background: 'white'}}>
                            <div className="card-image">
                              {p.subsidi > 0 ? (<div className='free-shipping' style={{background: (currentCompany ? currentCompany.company_color2 : '')}}>GRATIS ONGKIR</div>) : null}
                              {p.cashback > 0 ? (<div className='cashback' style={{background: (currentCompany ? currentCompany.company_color4 : ''), left: p.subsidi < 1 ? 0 : 75 }}>CASHBACK</div>) : null}
                              {discount > 0 ? (<div className='discount-label'>{parseInt(discount)}%<br/><span className='off-disc'>OFF</span></div>) : null}
                              {p.point > 0 ? (<div className='point-label'>{parseInt(p.point)}<br/><span className='off-disc'>POIN</span></div>) : null}
                              {p.stock < 1 ? (<div className='outstock-label'>STOK HABIS</div>) : null}
                              <img src={p.image_url}/>
                            </div>
                            <div className="card-content">
                              <div className='title-card' style={{fontWeight: 500}}>
                                <LinesEllipsis
                                  text={p.title}
                                  maxLine='2'
                                  ellipsis='...'
                                  trimRight
                                  basedOn='letters'
                                />  
                              </div>
                              <div className='category-card'>{p.category_name}</div>
                              <div className='price-card' style={{color: (currentCompany ? currentCompany.company_color2 : '')}}>
                                {discount > 0 ? (<span className='discount-price'>{numberFormat(price)}</span>) : null} {numberFormat(parseInt(price)-(parseInt(price)*parseInt(discount)/100))}
                              </div>
                            </div>
                          </Link>
                        </div>
                      )
                    })
                  }
                </>
              ) : (
                <div className='col s12 m12'>
                  <div className='blank-message'>
                    Data Tidak Ditemukan
                  </div>
                </div>
              )}

              {paging ? (
                <div className='col s12 m12'>
                  <div onClick={loadMoreData} className='load-more-btn' style={{background: '#fff'}}>
                    Load More
                    {loadMore ? (
                      <center><Dots color='#444' /></center>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </>
          )
        }
      </div>
      <BottomSheet/>
    </>
    ) : null
  );
}

export default Products;
