import React, { useEffect, useState} from 'react';
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, useParams } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';
import { useAlert } from 'react-alert';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const Carts = () => {
  const alert = useAlert();
  const history = useHistory();
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const current_order_id = ReactSession.get("current_order_id");
  const currentCompany = ReactSession.get("current_company");

  const [currentMember, setCurrentMember] = useState({});
  const [originId, setOriginId] = useState(null);
  const [destinationId, setDestinationId] = useState(null);
  const [shippingPrice, setShippingPrice] = useState(0);
  const [subsidi, setSubsidi] = useState(0);
  const [company, setCompany] = useState({});
  const [paymentNumbers, setPaymentNumbers] = useState({});
  const [expeditionsOld, setExpeditionsOld] = useState([]);
  const [expeditions, setExpeditions] = useState([]);
  const [expedition, setExpedition] = useState({});
  const [expeditionServices, setExpeditionServices] = useState([]);
  const [expeditionWeight, setExpeditionWeight] = useState(0);
  const [order, setOrder] = useState({});
  const [orderItems, setOrderItems] = useState([]);
  const [feeCOD, setFeeCOD] = useState(0);

  const [messageVoucherStatus, setMessageVoucherStatus] = useState(false);
  const [messageVoucher, setMessageVoucher] = useState('');
  const [messageVoucherSuccess, setMessageVoucherSuccess] = useState('');
  const [voucherCode, setVoucherCode] = useState('');

  const [orderItem, setOrderItem] = useState({});
  const [showBoxCart, setShowBoxCart] = useState(false);
  const [product, setProduct] = useState({});
  const [productPrices, setProductPrices] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [variant1, setVariant1] = useState('');
  const [variant2, setVariant2] = useState('');
  const [variant1Obj, setVariant1Obj] = useState({});
  const [variant2Obj, setVariant2Obj] = useState({});
  const [productPrice, setProductPrice] = useState(0);
  const [variants2, setVariants2] = useState([]);
  const [haveVariant2, setHaveVariant2] = useState(false);
  const [spinnerModal, setSpinnerModal] = useState(false);
  const [sellPrice, setSellPrice] = useState(0);

  const [editUserOrder, setEditUserOrder] = useState(false);
  const [editUserSender, setEditUserSender] = useState(false);
  const [address, setAddress] = useState(null);
  const [addressCode, setAddressCode] = useState(null);
  const [fullAddress, setFullAddress] = useState('');
  const [queryDistrict, setQueryDistrict] = useState('');
  const [districts, setDistricts] = useState([]);

  const [dropship, setDropship] = useState(false);
  const [customerName, setCustomerName] = useState();
  const [customerEmail, setCustomerEmail] = useState();
  const [customerPhone, setCustomerPhone] = useState();
  const [customerPaymentMethod, setCustomerPaymentMethod] = useState('Transfer');
  
  const [storeName, setStoreName] = useState();
  const [storeNumber, setStoreNumber] = useState();
  const [paymentMethod, setPaymentMethod] = useState();

  const [expeditionId, setExpeditionId] = useState();
  const [expeditionName, setExpeditionName] = useState();
  const [expeditionServiceName, setExpeditionServiceName] = useState();
  const [expeditionService, setExpeditionService] = useState();
  const [expeditionNumber, setExpeditionNumber] = useState();

  const [spinnerScreen, setSpinnerScreen] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [spinnerCheckout, setSpinnerCheckout] = useState(false);
  const [errorMessages, setErrorMessages] = useState();

  const IncrementItemModal = () => {
    let qty = (quantity+1);
    setQuantity(qty)
  }

  const DecreaseItemModal = () => {
    let qty = quantity-1
    if(qty <= 1){
      setQuantity(1)
    }else{
      setQuantity((quantity-1))
    }
  }

  const IncrementItem = (quantity, oi) => {
    let qty = (quantity+1);
    updateQuantity(qty, oi)
  }

  const DecreaseItem = (quantity, oi) => {
    let qty = quantity-1
    if(qty <= 1){
      updateQuantity(1, oi)
    }else{
      updateQuantity((quantity-1), oi)
    }
  }

  const updateQuantity = (quantity, oi) => {
    const _oi = orderItems.filter((i) => i.id === oi)[0]
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const paramsData = { 
      drop_price: _oi.sell_price, quantity: quantity, call_order: true, include: 'order_items, product'
    };

    API.put(`order_items/${oi}`, paramsData, config)
      .then(res => {
        // alert.success('Jumlah Produk Yang Di Pesan Berhasil Dirubah')
        const data = res.data.data;
        setOrderItems(data.order_items);
        setOrder(data);
        setExpeditionWeight(data.expedition_weight);
        setGetServiceExpedition(expeditionId, originId, destinationId, data.expedition_weight, data);
      }).catch(error => {
        if(error === undefined){
          alert.show('Gagal, Telah terjadi kesalahan server');
        }else{
          alert.show(`Gagal ${error.response.data.meta.status.message}`);
        }
      })
  }

  const deleteItem = (oi) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    
    API.delete(`order_items/${oi}?call_order=true&include=order_items,product`, config)
      .then(res => {
        alert.success('Produk Yang Di Pesan Berhasil Dihapus')
        const data = res.data.data;
        setOrderItems(data.order_items);
        setOrder(data);
        setExpeditionWeight(data.expedition_weight);
        setGetServiceExpedition(expeditionId, originId, destinationId, data.expedition_weight, data);
        ReactSession.remove("current_order_count");
        ReactSession.set("current_order_count", data.order_items.length);
      }).catch(error => {
        if(error === undefined){
          alert.show('Gagal, Telah terjadi kesalahan server');
        }else{
          alert.show(`Gagal ${error.response.data.meta.status.message}`);
        }
      })
  }

  const loadData = () => {
    setDestinationId(currentUser.address_code);
    setCustomerName(currentUser.full_name);
    setCustomerEmail(currentUser.email);
    setCustomerPhone(currentUser.phone1);
    setCustomerPaymentMethod('Transfer');

    setAddress(currentUser.address);
    setAddressCode(currentUser.address_code);
    setFullAddress(`${currentUser.district}, ${currentUser.city}, ${currentUser.province}`)
    setDistricts([{id: currentUser.address_code, name: currentUser.district+','+currentUser.city+','+currentUser.province }])

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'order_items, product'} };
    let _current_order_id = current_order_id === undefined ? 0 : current_order_id
    API.get(`orders/${_current_order_id}`, config)
      .then(res => {
        const data = res.data.data;
        setOrder(data);
        setOrderItems(data.order_items);
        setExpeditionWeight(data.expedition_weight);

        const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'payment_numbers, expeditions'} };
        API.get(`user_company`, config2)
          .then(res => {
            const com = res.data.data;
            setCompany(com);
            setStoreName(com.full_name);
            setStoreNumber(com.phone1);
            setPaymentNumbers(com.payment_numbers);
            setExpeditions(com.expeditions);
            setExpeditionsOld(com.expeditions);
            setOriginId(data.store_address_code);

            if(com.expeditions && com.expeditions.length > 0){
              setExpedition(com.expeditions[0]);
              setExpeditionId(com.expeditions[0].id);
              getOngkir(com.expeditions[0].name.toLowerCase(), data.store_address_code, currentUser.address_code, data.expedition_weight, data);
            }
          })
      })
    
    API.get(`sessions`, config )
      .then(res => {
        const data = res.data.data;
        setCurrentMember(data);
      })
      .catch(error => {
        console.log(error.response);
      })
    
  }

  const onHideCart = () => {
    setShowBoxCart(false);
    setOrderItem({});
    setProduct({});
    // let elSide = document.querySelectorAll('.sidenav-overlay');
    // let elBody = document.querySelectorAll('body');
    // elSide.forEach((item: any) => {
    //   item.style.display = 'none';
    // })
    // elBody.forEach((item: any) => {
    //   item.style.overflow = 'auto';
    // })
  }

  const modalEditCart = (id) => {
    setShowBoxCart(true);
    setSpinnerModal(true);
    // let elSide = document.querySelectorAll('.sidenav-overlay');
    // let elBody = document.querySelectorAll('body');
    // elSide.forEach((item: any) => {
    //   item.style.display = 'block';
    //   item.style.opacity = '1';
    // })
    // elBody.forEach((item: any) => {
    //   item.style.overflow = 'hidden';
    // })

    let order_item = orderItems.filter(o => o.id === id )[0]
    setOrderItem(order_item);
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'user, product_variants, product_variant_prices, product_prices'} };

    API.get(`products/${order_item.product_id}`, config)
      .then(res => {
        setSpinnerModal(false);
        const data = res.data.data;
        setProduct(data);
        setQuantity(order_item.quantity);
        M.AutoInit();
        setProductPrices(data.product_prices);
        setProductPrice(data.sell_price);
        setSellPrice(parseInt(data.sell_price)-(parseInt(data.sell_price)*parseInt(data.discount_customer)/100));
        
        if(data.product_variants && data.product_variants.filter(o => o.parent_id === null).length > 0){
          let varian_sku = data.product_variants.filter(o => o.parent_id === null && o.sku === order_item.variant_sku_1)[0]
          let opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_sku: varian_sku.sku} }
          setVariant1(varian_sku.sku);
          setVariant1Obj(varian_sku)

          if(data.product_type == 'price_on_variant' && data.product_prices.length > 0){
            let pPrice = data.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser.group_user_id) && o.product_variant_id === varian_sku.id )[0]
            if(pPrice !== undefined){
              setProductPrice(pPrice.sell_price);
            }else{
              setProductPrice(varian_sku.sell_price);
            }
            setSellPrice(parseInt(varian_sku.sell_price)-(parseInt(varian_sku.sell_price)*parseInt(data.discount_customer)/100));
          }else{
            let pPrice = data.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser.group_user_id) && o.product_variant_id === null )[0]
            if(pPrice !== undefined){
              setProductPrice(pPrice.sell_price);
            }else{
              setProductPrice(varian_sku.sell_price);
            }
          }
          
          API.get(`product_variants`, opts)
            .then(res => {
              const data = res.data.data;
              if(data && data.length > 0){
                setVariant2(order_item.variant_sku_2);
                setVariant2Obj(data.filter(o => o.sku === order_item.variant_sku_2)[0])
                setHaveVariant2(true)
                setVariants2(data);
              }else{
                setVariant2('');
                setVariant2Obj({})
                setHaveVariant2(false)
                setVariants2([]);
              }
            })
        }else{
          let pPrice = data.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser.group_user_id) && o.product_variant_id === null )[0]
          setProductPrice(pPrice.sell_price);
        }
      })
    
  }

  const loadVariant = (sku) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {sku: sku} };
    setVariant1(sku);

    API.get(`product_variant_sku`, config)
      .then(res => {
        const data = res.data.data;
        setVariant1Obj(data);
        
        let pPrice = product.sell_price;
        if(product.product_type == 'price_on_variant'){
          pPrice = productPrices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser.group_user_id) && o.product_variant_id === data.id )[0]
          setSellPrice(parseInt(data.sell_price)-(parseInt(data.sell_price)*parseInt(product.discount_customer)/100));
        }else{
          pPrice = productPrices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser.group_user_id) && o.product_variant_id === null )[0]
        }
        if(pPrice !== undefined){
          setProductPrice(pPrice.sell_price);
        }else{
          setProductPrice(data.sell_price);
        }

      })
    
    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_sku: sku} };
    API.get(`product_variants`, config2)
      .then(res => {
        const data = res.data.data;
        if(data && data.length > 0){
          setVariant2(data[0].sku);
          setVariant2Obj(data[0])
          setHaveVariant2(true)
          setVariants2(data);
        }else{
          setVariant2('');
          setVariant2Obj({})
          setHaveVariant2(false)
          setVariants2([]);
        }
      })
  }

  const setLoadVariant2 = (sku) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {sku: sku} };
    setVariant2(sku);

    API.get(`product_variant_sku`, config)
      .then(res => {
        const data = res.data.data;
        setVariant2Obj(data)
      })
  }
  
  const editCart = (id) => {
    setSpinner(true);
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const paramsData = { 
      drop_price: sellPrice, variant_sku_1: variant1, variant_sku_2: variant2, quantity: quantity, call_order: true, include: 'order_items,product'
    };
    
    API.put(`order_items/${id}`, paramsData, config)
      .then(res => {
        setSpinner(false);
        alert.success('Keranjang Belanja Berhasil Dirubah!')
        const data = res.data.data;
        setShowBoxCart(false);
        setOrderItems(data.order_items);
        setOrder(data);
        setExpeditionWeight(data.expedition_weight);
        setGetServiceExpedition(expeditionId, originId, destinationId, data.expedition_weight, data);
        // let elSide = document.querySelectorAll('.sidenav-overlay');
        // let elBody = document.querySelectorAll('body');
        // elSide.forEach((item: any) => {
        //   item.style.display = 'none';
        // })
        // elBody.forEach((item: any) => {
        //   item.style.overflow = 'auto';
        // })
      }).catch(error => {
        setSpinner(false);
        if(error === undefined){
          setErrorMessages('Gagal, Telah terjadi kesalahan server');
        }else{
          setErrorMessages(`Gagal ${error.response.data.meta.status.message}`);
        }
      })
  }

  const handleOnSearch = (string, results) => {
    console.log(string, results)
    findDistrict(string)
  }

  const handleOnHover = (result) => {
    console.log(result)
  }

  const handleOnSelect = (item) => {
    setAddressCode(item.id)
    setFullAddress(item.name)
    setDestinationId(item.id)
    setGetServiceExpedition(expeditionId, originId, item.id, order.expedition_weight);
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return item;
   // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  }

  const findDistrict = (query) => {
    setQueryDistrict(query);
    console.log('find district '+query)
    API.get(`regions`, {params: {key: query}} )
      .then(res => {
        const data = res.data.data;
        setDistricts(data);
        console.log(data)
      })
  }
  
  const onChangeUserOrder = () => {
    editUserOrder === true ? setEditUserOrder(false) : setEditUserOrder(true)
  }

  const onChangeUserSender = () => {
    editUserSender === true ? setEditUserSender(false) : setEditUserSender(true)
  }

  const onSelectDropshiper = (value) => {
    setDropship(!dropship)

    if(!dropship === true){
      setEditUserSender(true)
      setStoreName(currentUser.full_name);
      setStoreNumber(currentUser.phone1);
      setCustomerPaymentMethodLoad(customerPaymentMethod, true);
    }else{
      setEditUserSender(false)
      setStoreName(company.full_name);
      setStoreNumber(company.phone1);
      setCustomerPaymentMethodLoad(customerPaymentMethod, false);
    }
  }

  const setGetServiceExpedition = (ex_id, origin, destination, weight, order) => {
    let _expedition = expeditionsOld.filter((x) => x.id === parseInt(ex_id))[0];
    
    setExpeditionId(parseInt(ex_id));
    setExpedition(_expedition);
    
    if(_expedition){
      // getOngkir(_expedition.name.toLowerCase(), origin, destination, weight)
      setCustomerPaymentMethodLoad(customerPaymentMethod, dropship, order, _expedition, destination, weight);
    }
  }

  const setCustomerPaymentMethodLoad = (pMethod, drops, _order, ex, _destination, _weight) => {
    setCustomerPaymentMethod(pMethod);
    
    let _feeCOD = 0
    let statusDropship = drops !== undefined ? drops : dropship
    let dOrder = _order ? _order : order
    let exp = ex ? ex : expedition
    let h_expeditions = expeditionsOld
    let dDestination = _destination ? _destination : destinationId
    let dWeight = _weight ? _weight : expeditionWeight
    
    if(pMethod.includes('COD')){
      h_expeditions = expeditionsOld.filter((ex) => ex.cod === true);
      setExpeditions(h_expeditions);
      if(exp.cod !== true){
        exp = h_expeditions[0]
      }
    }else{
      setExpeditions(h_expeditions);
      if(exp === undefined){
        exp = h_expeditions[0]
      }
    }

    if(exp){
      setExpeditionId(parseInt(exp.id));
      setExpedition(exp);
      setExpeditionName(exp.name);

      const config = { 
        headers: { Authorization: `Bearer ${token}` }, 
        params: { name: exp.name.toLowerCase(), origin_id: originId, district_id: dDestination, weight: dWeight} 
      };
      
      API.get(`expedition_shipping`, config)
        .then(res => {
          const data = res.data.report;
          setExpeditionServices(data);
          if(data && data.length > 0){
            let expd = data[0]
            
            setExpeditionService(expd.service+'-'+expd.price.toString()); 
            setExpeditionServiceName(expd.service); 
            setShippingPrice(parseInt(expd.price));

            if(exp && exp.cod === true && pMethod.includes('COD')){
              if(exp.cod_type_percentage === 'total_order'){
                _feeCOD = parseInt(dOrder.sell_price) * (exp.cod_presentage/100);
              }else{
                _feeCOD = (parseInt(dOrder.sell_price)+parseInt(expd.price)) * (exp.cod_presentage/100);
              }
            }else{
              _feeCOD = 0;
            }
            setFeeCOD(_feeCOD);
            
            if(dOrder && dOrder.subsidi > 0 && parseInt(dOrder.subsidi) >= (parseInt(expd.price)+_feeCOD) ){
              setSubsidi((parseInt(expd.price)+_feeCOD));
            }
          }
        })

    }else{
      setFeeCOD(0);
    }
  }

  const getOngkir = (ex, origin, destination, weight, order) => {
    setExpeditionName(ex)
    
    let or = origin ? origin : originId
    let des = destination ? destination : addressCode
    let wei = weight ? weight : order.expedition_weight
    const config = { 
      headers: { Authorization: `Bearer ${token}` }, 
      params: { 
        name: ex,
        origin_id: or,
        district_id: des,
        weight: wei
      } 
    };
    
    API.get(`expedition_shipping`, config)
      .then(res => {
        const data = res.data.report;
        setExpeditionServices(data)

        if(data && data.length > 0){
          let expd = data[0]
          setExpeditionService(expd.service+'-'+expd.price.toString()); 
          setExpeditionServiceName(expd.service); 
          setShippingPrice(parseInt(expd.price));
    
          if(order && order.subsidi > 0 && parseInt(order.subsidi) >= parseInt(expd.price) ){
            setSubsidi(parseInt(expd.price));
          }
        }
      })
  }

  const setServiceExpedition = (select) => {
    let ser = select.split('-')[0]
    let ship = select.split('-')[1]
    let _feeCOD = 0

    setExpeditionService(select); 
    setExpeditionServiceName(ser); 
    setShippingPrice(parseInt(ship));

    if(expedition && expedition.cod === true && customerPaymentMethod.includes('COD')){
      if(expedition.cod_type_percentage === 'total_order'){
        _feeCOD = parseInt(order.sell_price) * (expedition.cod_presentage/100);
      }else{
        _feeCOD = (parseInt(order.sell_price)+parseInt(ship)) * (expedition.cod_presentage/100);
      }
    }else{
      _feeCOD = 0;
    }
    setFeeCOD(_feeCOD);

    if(order && order.subsidi > 0 && parseInt(order.subsidi) >= (parseInt(ship)+_feeCOD) ){
      setSubsidi((parseInt(ship)+_feeCOD));
    }
  }

  const checkoutAct = () => {
    setSpinnerCheckout(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` }
    };

    const paramsData = { 
      id: order.id,
      dropship: dropship,
      mitra: true,
      customer_name: customerName,
      customer_phone: customerPhone,
      customer_address: address,
      customer_address_code: addressCode,
      customer_payment_method: customerPaymentMethod,
      store_name: storeName,
      store_number: storeNumber,
      expedition_id: expeditionId,
      expedition_name: expeditionName,
      expedition_service: expeditionServiceName,
      expedition_number: expeditionNumber,
      voucher_code: voucherCode
    };

    API.post(`orders`, paramsData, config)
      .then(res => {
        const data = res.data.data;
        alert.success('Pesanan Baru Berhasil')
        ReactSession.remove("current_order_id");
        ReactSession.remove("current_order_count");
        setSpinnerCheckout(false);
        history.push(`/orders/${data.id}`);
      }).catch(error => {
        if(error === undefined){
          alert.show('Gagal Telah terjadi kesalahan server')
        }else{
          alert.show(`Gagal ` + error.response.data.meta.status.message);
        }
        setSpinnerCheckout(false);
      })
  }

  const getVoucherCode = () => {
    if(voucherCode === ''){
      setMessageVoucherStatus(true);
      setMessageVoucher('Kode Voucher Belum Di Isi, Silahkan Isi Terlebih Dahulu!');
    }else{
      const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: ''} };

      API.get(`voucher_check/${voucherCode}`, config)
        .then(res => {
          const data = res.data.data;
          setMessageVoucherStatus(false);
          setMessageVoucher('');
          
          if(data){
            if(data.category === 'discount'){
              setMessageVoucherSuccess(`Kode Voucher Berhasil Ditemukan, Anda akan mendapatkan potongan Diskon ${data.voucher_value}% ketika melakukan pembelian !`);
            }else{
              setMessageVoucherSuccess(`Kode Voucher Berhasil Ditemukan, Anda akan mendapatkan potongan Harga Rp.${data.voucher_value} ketika melakukan pembelian !`);
            }
          }
        }).catch(error => {
          setMessageVoucherStatus(true);
          setMessageVoucherSuccess('');
          if(error === undefined){
            setMessageVoucher('Kode Voucher Tidak Ditemukan!');
          }else{
            setMessageVoucher(error.response.data.meta.status.message);
          }
        })
    }
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/login")
    }
    
    if(currentUser){
      loadData();
    }
    
  },[])

  return (
    currentCompany && currentUser ? (
    <>
      <HeaderScreen title="Keranjang Belanja Saya"/>
      <div className='sidenav-overlay'></div>
      <div className="row back-white">
        <div className="">
          <div className="card horizontal card-cart">
            <b>Alamat Pengiriman</b>
            <a href='#' className='btn-right-label2' onClick={ onChangeUserOrder } ><i className="material-icons">edit</i> Ubah</a>
          </div>

          <div className="card horizontal card-cart">
            <div className="row m-bottom0">
              { editUserOrder ? (
                <>
                  <div className="input-field col s12 m-bottom0">
                    <input placeholder="Nama Penerima" type="text" className="validate" value={customerName} onChange={e => setCustomerName(e.target.value)} />
                    <label className="active" for="icon_prefix">Nama Penerima</label>
                  </div>
                  <div className="input-field col s6 m-bottom0">
                    <input placeholder="No.Hp" type="text" className="validate" value={customerPhone} onChange={e => setCustomerPhone(e.target.value)} />
                    <label className="active" for="icon_prefix">No.Hp</label>
                  </div>
                  <div className="input-field col s6 m-bottom0">
                    <input placeholder="Email" type="text" className="validate" value={customerEmail} onChange={e => setCustomerEmail(e.target.value)} />
                    <label className="active" for="icon_prefix">Email</label>
                  </div>
                </>
              ) : null } 

              { editUserOrder ? (
                <div className="input-field col s12 autocomplete-field">
                  <ReactSearchAutocomplete
                    items={districts}
                    onSearch={handleOnSearch}
                    onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    // formatResult={formatResult}
                    />
                  <label className="active" for="icon_prefix">
                    Kecamatan Tujuan Pengiriman
                  </label>
                </div>
              ) : (
                <div className='full-address-box'>
                  <p>
                    <div>
                      <b>{customerName} ({customerPhone})</b>
                    </div>
                    <div>
                      {customerEmail}
                    </div>
                    <div>
                      {address}, {fullAddress}
                    </div>
                  </p>
                </div>
              )}

              { editUserOrder ? (
                <div className="input-field col s12 m-bottom0">
                  <input placeholder="Alamat Lengkap" type="text" className="validate" value={address} onChange={e => setAddress(e.target.value)} />
                  <label className="active" for="icon_prefix">Alamat Lengkap Pengiriman</label>
                </div>
              ) : null } 
            </div>
          </div>
          
          <div className="card horizontal card-cart">
            <b>Detail Pemesanan</b>
            { currentUser && currentUser.group_user_id > 0 ? (
              <label className='btn-right-label2'>
                <input type="checkbox" defaultChecked={dropship} onChange={() => onSelectDropshiper(!dropship)} />
                <span style={{fontSize: 11}}>Kirim Sebagai Dropshiper</span>
              </label>
            ) : null }
          </div>
          
          { editUserSender ? (
            <div className="card horizontal card-cart">
              <div className="row m-bottom0">
                <>
                  <div className="input-field col s6 m-bottom0">
                    <input placeholder="Nama Pengirim" type="text" className="validate" value={storeName} onChange={e => setStoreName(e.target.value)} />
                    <label className="active" for="icon_prefix">Nama Pengirim</label>
                  </div>
                  <div className="input-field col s6 m-bottom0">
                    <input placeholder="No.Hp Pengirim" type="text" className="validate" value={storeNumber} onChange={e => setStoreNumber(e.target.value)} />
                    <label className="active" for="icon_prefix">No.Hp Pengirim</label>
                  </div>
                  <div className="input-field col s12 m-bottom0 m-top0 p-top0 select-none-ui">
                    <label className='select-label'>Pilih Metode Pembayaran</label>
                    <select id="sel" className='select' value={customerPaymentMethod} onChange={e => setCustomerPaymentMethodLoad(e.target.value, dropship, order)} >
                      <option value="Transfer">Transfer</option>
                      <option value="COD">COD (Bayar di Tempat)</option>
                    </select>
                  </div>    
                </>
              </div>
            </div>
          ) : null
          }

          { orderItems ? (
            orderItems.map((oi) => {
              return (
                <div key={oi.id} className="card horizontal card-cart">
                  <div className="card-image">
                    <img src={oi.product.image_url}/>
                  </div>
                  <div className="card-stacked">
                    <div className="card-content padding-top0">
                      <div className='col col s9 m9 padding0'>
                        <div className='card-product'>{oi.product_name}</div>
                        { oi.variant_name_1 ? (
                          <div className='card-variant'>
                            <label className='label label-default' onClick={e => modalEditCart(oi.id)}>Varian {oi.variant_name_1} {oi.variant_name_2}</label>
                          </div>
                        ) : null}
                        <div className='card-variant'>
                          Harga Beli 
                          {oi.discount > 0 ? (<span className='discount-price'> {currencyFormat(parseInt(oi.store_price*oi.quantity)+parseInt(oi.discount))} </span>) : null}
                          <b className=''> {currencyFormat(parseInt(oi.store_price*oi.quantity))} </b>
                        </div>
                        {
                          dropship === true ? (
                            <div className='card-variant'>Jual Ke Konsumen <b className=''>{currencyFormat(parseInt(oi.sell_price*oi.quantity))}</b></div>   
                          ) : null
                        }
                        <div className='card-variant'>Berat <b>{parseInt(oi.product.weight*oi.quantity)} gr</b></div>
                        { oi.member_point > 0 ? (
                          <div className='card-variant'>
                            <label className='label label-point'>{oi.member_point} POIN</label>
                          </div>
                        ) : null }
                        { oi.member_cashback > 0 ? (
                          <div className='card-variant'>
                            <label className='label label-cashback'>{currencyFormat(oi.member_cashback)} Cashback</label>
                          </div>
                        ) : null }
                      </div>
                      
                      <div className='col col s3 m3 padding0'>
                        <button className='btn btn-delete no-radius' onClick={e => deleteItem(oi.id)}>Hapus</button>
                        <div className='input-group small'>
                          <button className='btn btn-default no-radius' onClick={e => IncrementItem(oi.quantity, oi.id)}>+</button>
                          <input placeholder="Jumlah" type="number" min='1' className="validate input-border" value={oi.quantity} onChange={e => updateQuantity(e.target.value, oi.id)} />
                          <button className='btn btn-default no-radius' onClick={e => DecreaseItem(oi.quantity, oi.id)}>-</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          ) : null }
          
          { currentUser && (currentUser.group_user_id === null || currentUser.group_user_id === 0) ? (
            <div className="card horizontal card-cart">
              <div className="row m-bottom0">
                <>
                  <div className="input-field col s12 m-bottom0 m-top0 p-top0 select-none-ui">
                    <label className='select-label'>Pilih Metode Pembayaran</label>
                    <select id="sel" className='select' value={customerPaymentMethod} onChange={e => setCustomerPaymentMethodLoad(e.target.value, dropship, order)} >
                      <option value="Transfer">Transfer</option>
                      <option value="COD">COD (Bayar di Tempat)</option>
                    </select>
                  </div>    
                </>
              </div>
            </div>
          ) : null
          }

          <div className="card horizontal card-cart">
            <div className='row m-bottom0'>
              <div className="input-field col s4 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 select-none-ui">
                <select className='select' value={expeditionId} onChange={e => setGetServiceExpedition(e.target.value)} >
                  { expeditions ? (
                    expeditions.map((ex) => {
                      return (
                        <option key={ex.id} value={ex.id} >{ex.name.toUpperCase() }</option>
                      )
                    })
                  ) : null }
                </select>
              </div>
              <div className="input-field col s8 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 select-none-ui">
                <select className='select' value={expeditionService} onChange={e => setServiceExpedition(e.target.value)} >
                  { expeditionServices ? (
                    expeditionServices.map((ex) => {
                      return (
                        <option key={ex.service + '-' + ex.price.toString()} value={ex.service + '-' + ex.price.toString()}>{ex.description} ({ex.service}) {currencyFormat(ex.price)}</option>
                      )
                    })
                  ) : null }
                </select>
              </div>  
              
              { expeditionName && expeditionName.includes('Marketplace') ? (
                <div className="input-field col s12 m-bottom0 m-bottom0 p-bottom0">
                  <input placeholder="Masukan Resi Marketplace" type="text" className="input-back" value={expeditionNumber} onChange={e => setExpeditionNumber(e.target.value)} />
                </div>
              ) : null }
            </div>
          </div>

          <div className="card horizontal card-cart">
            <div className="row m-bottom0">
              <div className="input-field col s8 m-bottom0">
                <input placeholder="Kode Voucher" type="text" className="input-back" value={voucherCode} onChange={e => setVoucherCode(e.target.value)} />
                <label className="active" for="icon_prefix">Masukan Kode Voucher</label>
              </div>
              <div className="input-field col s4 m-bottom0">
                <button onClick={(e) => getVoucherCode()} className='btn btn-default' style={{fontSize: 12, width: '100%', borderRadius: 0, height: 45}}>REDEEM</button>
              </div>

              {
                messageVoucherStatus ? (
                  <div className="input-field col s12 m-bottom0" style={{marginTop: 0, color: 'red', paddingTop: 0}}>
                    <span>{messageVoucher}</span>
                  </div>
                ) : null
              }


              { 
                messageVoucherSuccess && messageVoucherSuccess.length > 0 ? (
                  <div className="input-field col s12 m-bottom0" style={{marginTop: 0, paddingTop: 0}}>
                    <span>{messageVoucherSuccess}</span>
                  </div>
                ) : null
              }
            </div>
          </div>
          
          <div className="card horizontal card-cart">
            <div className='row m-bottom0'>
              <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0">
                <b>Transaksi Anda</b>
              </div>
              <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0 text-right">
                <b>Rincian Harga</b>
              </div>
            </div>
          </div>
          <div className="card horizontal card-cart">
            <div className='row m-bottom0'>
              <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                Subtotal Produk Anda
              </div>
              <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                {currencyFormat(order.store_price)}
              </div>
              <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                Subtotal Ongkos Kirim
              </div>
              <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                {currencyFormat((parseInt(shippingPrice)))}
              </div>
              { customerPaymentMethod === 'COD' ? (
                <>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                    Biaya Penanganan COD
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                    {currencyFormat( (parseInt(feeCOD)) )}
                  </div>
                </>
              ) : null}
              { order.subsidi > 0 ? (
                <>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                    Potongan Ongkir Sampai
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                    - {currencyFormat(order.subsidi)}
                  </div>
                </>
              ) : null }
              
              { currentMember && parseInt(currentMember.virtual_money) > 0 ? (
                <>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                    BAYAR PAKAI SALDO
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                    { parseInt(currentMember.virtual_money) >= (parseInt(order.store_price)+(parseInt(shippingPrice)+parseInt(feeCOD)+parseInt(order.uniq_code)) - parseInt(subsidi)) ? (
                      <>
                        - {currencyFormat( (parseInt(order.store_price)+(parseInt(shippingPrice)+parseInt(feeCOD)+parseInt(order.uniq_code)) - parseInt(subsidi)))}
                      </>
                    ) : (
                      <>
                        - {currencyFormat( (parseInt(order.store_price)+(parseInt(shippingPrice)+parseInt(feeCOD)+parseInt(order.uniq_code)) - parseInt(subsidi)) - (parseInt(order.store_price)+(parseInt(shippingPrice)+parseInt(feeCOD)+parseInt(order.uniq_code)) - parseInt(subsidi) - parseInt(currentMember.virtual_money)) )}
                      </>
                    )}
                  </div>
                </>
              ) : null}
              
              <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                TOTAL PEMBAYARAN ANDA
              </div>
              <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                { parseInt(currentMember.virtual_money) >= (parseInt(order.store_price)+(parseInt(shippingPrice)+parseInt(feeCOD)+parseInt(order.uniq_code)) - parseInt(subsidi)) ? (
                  <b>{currencyFormat(0)}</b>
                ) : (
                  <b>{currencyFormat(parseInt(order.store_price)+(parseInt(shippingPrice)+parseInt(feeCOD)+parseInt(order.uniq_code)) - parseInt(subsidi) - parseInt(currentMember.virtual_money))}</b>
                )}
              </div>
            </div>
          </div>

          { dropship === true ? (
            <>
              <div className="card horizontal card-cart">
                <div className='row m-bottom0'>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0">
                    <b>Detail Tagihan Ke Konsumen</b>
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0 text-right">
                    <b>Rincian Harga</b>
                  </div>
                </div>
              </div>
              <div className="card horizontal card-cart">
                <div className='row m-bottom0'>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                    Subtotal Produk Konsumen
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                    {currencyFormat(order.sell_price)}
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                    Subtotal Ongkos Kirim
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                    {currencyFormat((parseInt(shippingPrice)))}
                  </div>
                  { dropship === true && customerPaymentMethod === 'COD' ? (
                    <>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                        Biaya Penanganan COD
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                        {currencyFormat( (parseInt(feeCOD)) )}
                      </div>
                    </>
                  ) : null}
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                    TOTAL {customerPaymentMethod.toUpperCase()}
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                    <b>{currencyFormat(parseInt(order.sell_price)+(parseInt(shippingPrice)+parseInt(feeCOD)+parseInt(order.uniq_code)))}</b>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <div className="row border-bottom add-chart-seaction">
            <div className="col s6 m6">
              <div className="on-total">
                { parseInt(currentMember.virtual_money) >= (parseInt(order.store_price)+(parseInt(shippingPrice)+parseInt(feeCOD)+parseInt(order.uniq_code)) - parseInt(subsidi)) ? (
                  <b>{currencyFormat(0)}</b>
                ) : (
                  <b>{currencyFormat(parseInt(order.store_price)+(parseInt(shippingPrice)+parseInt(feeCOD)+parseInt(order.uniq_code)) - parseInt(subsidi) - parseInt(currentMember.virtual_money))}</b>
                )}
              </div>
            </div>
            <div className="col s6 m6">
              { spinnerCheckout ? (
                <div className="col s12 m12" >
                  <button className='btn btn-chart' style={{background: (currentCompany.company_color3)}}>
                    <center><Dots color='#fff' /></center>
                  </button>
                </div>
              ) : (
                <button className='btn btn-chart' style={{background: (currentCompany.company_color3)}} onClick={checkoutAct}> Buat Pesanan</button>
              ) }
            </div>
          </div>
          
          {  showBoxCart && orderItem && product ? (
            <div className="row border-bottom widget-box-chart">
              <div className="modal-widget-box-chart">
                {
                  spinnerModal ? (
                    <div className="col s12 m12" >
                      <center><Dots color='#444' /></center>
                    </div>
                  ) : (
                    <>
                      <div className='closeBtn' onClick={onHideCart}> X</div>
                      <div className="col s12 m12">
                        <div className="card horizontal">
                          <div className="card-image">
                            <img src={product.image_url}/>
                          </div>
                          <div className="card-stacked">
                            <div className="card-content padding-top0">
                              <p>{product.title}</p>
                              { variant1Obj.variant_name ? (
                                <div className='card-variant'>{variant1Obj.variant_name} {variant2Obj.variant_name}</div>
                              ) : null}
                              <b className='card-variant'>
                                { currentUser.group_user_id === null || currentUser.group_user_id === 0 ? (
                                  <>
                                    {product.discount_customer > 0 ? (<span className='discount-price'>{currencyFormat(productPrice)}</span>) : null} {currencyFormat(parseInt(productPrice)-(parseInt(productPrice)*parseInt(product.discount_customer)/100))}
                                  </>
                                ) : (
                                  <>
                                    {product.discount > 0 ? (<span className='discount-price'>{currencyFormat(productPrice)}</span>) : null} {currencyFormat(parseInt(productPrice)-(parseInt(productPrice)*parseInt(product.discount)/100))}
                                  </>
                                )}
                              </b>
                            </div>
                          </div>
                        </div>

                      </div>

                      {
                        product.product_variants && product.product_variants.length > 0 ? (
                          <>
                            <div className="col s5 m5">
                              <div className='title-card-label m-top-10'>Pilih Varian</div>
                            </div>
                            <div className="col s7 m7 select-none-ui">
                              <select className="sel" value={variant1} onChange={e => (loadVariant(e.target.value))} >
                                {
                                  product.product_variants.filter(o => o.parent_id === null).map((p) => {
                                    return (
                                      <option key={p.id} value={p.sku}>{p.variant_name}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </>
                        ) : null
                      }

                      {
                        haveVariant2 && variants2 ? (
                          <>
                            <div className="col s5 m5">
                              <div className='title-card-label m-top-10'>Pilih Varian 2</div>
                            </div>
                            <div className="col s7 m7 select-none-ui">
                              <select className="sel" value={variant2} onChange={e => (setLoadVariant2(e.target.value))} >
                                {
                                  variants2.map((p) => {
                                    return (
                                      <option key={p.id} value={p.sku}>{p.variant_name}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </>
                        ) : null
                      }

                      <div className="col s5 m5">
                        <div className='title-card-label m-top-10'>Jumlah</div>
                      </div>
                      <div className="col s7 m7">
                        <div className='input-group'>
                          <button className='btn btn-default no-radius' onClick={IncrementItemModal}>+</button>
                          <input placeholder="Jumlah" type="number" min='1' className="validate input-border" value={quantity} onChange={e => setQuantity(e.target.value)} />
                          <button className='btn btn-default no-radius' onClick={DecreaseItemModal}>-</button>
                        </div>
                      </div>

                      { currentUser.group_user_id > 0 ? (
                        <>
                          <div className="col s5 m5">
                            <div className='title-card-label m-top-10'>Harga Konsumen</div>
                          </div>
                          <div className="col s7 m7">
                            <input placeholder="Jumlah" type="number" min={sellPrice} className="validate input-border input-cell" value={sellPrice} onChange={e => setSellPrice(e.target.value)} />
                          </div>
                        </>
                      ) : null}

                      <div className="col s12 m12">
                        { errorMessages ? (
                          <div className="error-card">
                            <span>{errorMessages}</span>
                          </div>
                        ) : null}
                        { spinner ? (
                          <button className='btn btn-chart onBtnCart' style={{background: (currentCompany.company_color3)}}><i className="material-icons left">shopping_basket</i> <center><Dots color='#fff' /></center></button>
                        ) : (
                          <button className='btn btn-chart onBtnCart' style={{background: (currentCompany.company_color3)}} onClick={e => editCart(orderItem.id)}><i className="material-icons left">shopping_basket</i> Ubah Masukan Keranjang</button>
                        ) }
                      </div>
                    </>
                  )
                }
              </div>
            </div>
          ) : null }
        </div>
      </div>
    </>
    ) : null
  );
}

export default Carts;
