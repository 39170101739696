import React, { useEffect, useState, useParams } from 'react';
import { useAlert } from 'react-alert'
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import HeaderScreen from '../components/HeaderScreen'
import ScreenLoading from '../components/ScreenLoading'

const EditPassword = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const alert = useAlert();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [spinnerScreen, setSpinnerScreen] = useState(true);
  const [spinner, setSpinner] = useState(false);

  const changePassword = () => {
    setSpinner(true);
    const config = { headers: { Authorization: `Bearer ${token}`} };
    
    if(password === ''){
      setSpinner(false);
      alert.show('Gagal, Kata sandi baru anda belum di isi')
    }else if(passwordConfirmation === ''){
      setSpinner(false);
      alert.show('Gagal, Kata sandi lama anda belum di isi')
    }else if(password.length < 8){
      setSpinner(false);
      alert.show('Gagal, Kata sandi minimal 8 karakter')
    }else{
      API.put(`users/${currentUser.id}/change_password`, {password: password, password_confirmation: passwordConfirmation}, config )
        .then(res => {
          setSpinner(false);
          alert.success('Kata Sandi Berhasil Diubah')
        })
        .catch(error => {
          setSpinner(false);
          if(error === undefined){
            alert.show('Gagal, Terjadi kesalahan di server')
          }else{
            alert.show(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/login")
    }
    
    setSpinnerScreen(false);
    // let elements = document.querySelectorAll('.sidenav-overlay');
    // elements.forEach((item: any) => {
    //   item.style.display = 'none';
    // })
  }, []);

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed navbar-fixed-50">
        <nav className="nav-screen" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/profile" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Ubah Kata Sandi</span>
          </div>
        </nav>
      </div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <div className="row">
          <div className="form-edit">
            <div className="input-field col s12">
              <i className="material-icons prefix" style={{color: (currentCompany.company_color1)}}>lock</i>
              <input id="icon_prefix" type="password" className="validate" value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)} />
              <label for="icon_prefix" className='active active_fix'>Masukan Kata Sandi Lama</label>
            </div>
            
            <div className="input-field col s12">
              <i className="material-icons prefix" style={{color: (currentCompany.company_color1)}}>lock</i>
              <input id="icon_prefix" type="password" className="validate" value={password} onChange={e => setPassword(e.target.value)} />
              <label for="icon_prefix" className='active active_fix'>Masukan Kata Sandi Baru</label>
            </div>
            { spinner ? (
              <div className="input-field col s12">
                <button className="btn waves-effect waves-light btn-large full-width" style={{background: (currentCompany.company_color3)}} type="submit" name="action" >
                  <center><Dots color='#fff' /></center>
                </button>
              </div>
            ) : (
              <div className="input-field col s12">
                <button className="btn waves-effect waves-light btn-large full-width" style={{background: (currentCompany.company_color3)}} type="submit" name="action" onClick={changePassword} >
                  Simpan Perubahan
                </button>
              </div>
            ) }
          </div>
        </div>
      )}
    </>
    ) : null
  )
}

export default EditPassword