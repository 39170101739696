import React, { useEffect, useState, useParams } from 'react';
import { useAlert } from 'react-alert'
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';
import M from 'materialize-css';

const EditPayment = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const alert = useAlert();
  const history = useHistory();
  const [paymentBankNumber, setPaymentBankNumber] = useState('');
  const [paymentBankName, setPaymentBankName] = useState('');
  const [spinnerScreen, setSpinnerScreen] = useState(true);

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`} };

    API.get(`sessions`, config )
      .then(res => {
        const data = res.data.data;
        setPaymentBankName(data.payment_bank_name);
        setPaymentBankNumber(data.payment_bank_number);
      })
      .catch(error => {
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const changePassword = () => {
    const config = { headers: { Authorization: `Bearer ${token}`} };

    API.put(`users/${currentUser.id}/change_payment`, {payment_bank_name: paymentBankName, payment_bank_number: paymentBankNumber}, config )
      .then(res => {
        alert.success('Data Berhasil Diubah')
      })
      .catch(error => {
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/login")
    }
    
    loadData();
    setSpinnerScreen(false);
    
    // let elSide = document.querySelectorAll('.sidenav-overlay');
    // let elBody = document.querySelectorAll('body');
    // elSide.forEach((item: any) => {
    //   item.style.display = 'none';
    // })
    // elBody.forEach((item: any) => {
    //   item.style.overflow = 'auto';
    // })
  }, []);

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed navbar-fixed-50">
        <nav className="nav-screen" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/profile" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Ubah No Rekening Utama</span>
          </div>
        </nav>
      </div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <div className="row">
          <div className="m-top-10 col s12">
            <div className="input-field col s12 m-bottom0 m-top0 p-top0 select-none-ui">
              <label className='select-label'>Nama Bank</label>
              <select className='select' value={paymentBankName} onChange={e => setPaymentBankName(e.target.value)} >
                <option value="">Pilih Bank</option>
                <option value="BCA">BCA</option>
                <option value="BNI">BNI</option>
                <option value="MANDIRI">MANDIRI</option>
                <option value="BRI">BRI</option>
              </select>
            </div>
            <div className="col s12">
              <label className="m-bottom10">No Rekening</label>
              <input id="icon_prefix" className="input-back" value={paymentBankNumber} onChange={e => setPaymentBankNumber(e.target.value)} />
            </div>
            <div className="col s12">
              <button className="btn waves-effect waves-light btn-large full-width" style={{background: (currentCompany.company_color3)}} type="submit" name="action" onClick={changePassword} >
                Simpan Perubahan
              </button>
            </div>
          </div>
        </div>
      )}
    </>
    ) : null
  )
}

export default EditPayment