import React, { useState, useEffect } from 'react'
import { useHistory, BrowserRouter, Route, Link } from "react-router-dom";
import ReactSession from '../ReactSession';

const BottomSheet = () => {
  const currentCompany = ReactSession.get("current_company");
  const currentUser = ReactSession.get("current_user");
  const current_order_count = ReactSession.get("current_order_count");

  useEffect(() => {
  }, []);

  return (
    <div className='bottom-sheet'>
      <ul>
        <li>
          <Link to="/dashboard" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
            <i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>home</i> 
            <div style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }}>Home</div>
          </Link>
        </li>
        <li>
          <Link to="/products" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }} className={window.location.pathname === '/products' ? `active` : ``}>
            <i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>local_mall</i> 
            <div style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }}>Belanja</div>
          </Link>
        </li>

        <li>
          <Link to="/whistlists" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }} className={window.location.pathname === '/whistlists' ? `active` : ``}>
            <i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>favorite</i> 
            <div style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }}>Whistlist</div>
          </Link>
        </li>
        <li>
          <Link to="/orders" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }} className={window.location.pathname === '/orders' ? `active` : ``}>
            <i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>event_note</i> 
            <div style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }}>Pesanan</div>
          </Link>
        </li>
        <li>
          <Link to="/profile" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }} className={window.location.pathname === '/profile' ? `active` : ``}>
            <i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>account_circle</i> 
            <div style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }}>Profil Saya</div>
          </Link>
        </li>

      </ul>
    </div>
  )
}

export default BottomSheet
