import React, { useEffect, useState } from 'react';
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import BottomSheet from '../components/BottomSheet';
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import API from '../components/utils/api';
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import { useAlert } from 'react-alert';
import Countdown from 'react-countdown';
import { Helmet } from 'react-helmet';

import {
  ShopIcon, 
  MoneyIcon, 
  PointIcon, 
  AffiliateIcon,
  BlogIcon,
  CatalogIcon,
  CustomerIcon,
  InvoiceIcon,
  ReportIcon,
  ToolsIcon,
  ConfirmationIcon,
  ProspectIcon,
  DownloadIcon,
  SettingIcon,
  PresIcon
} from '../assets'

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function Dashboard() {
  const alert = useAlert();
  const [company, setCompany] = useState();
  const token = ReactSession.get("token");
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState();
  const [paymentNumber, setPaymentNumber] = useState('');
  const [virtualMoney, setVirtualMoney] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [mitra, setMitra] = useState(false);
  const [galleries, setGalleries] = useState([]);
  const [galleriesPromo, setGalleriesPromo] = useState([]);
  const [promo, setPromo] = useState({});
  const [categories, setCategories] = useState([]);

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'company, websetting_galleries, staff'}};

    API.get(`sessions`, config, {} )
      .then(res => {
        const data = res.data.data;
        setCurrentUser(data);
        setVirtualMoney(parseInt(data.virtual_money));
        setPaymentNumber(data.payment_bank_name+'-'+data.payment_bank_number);
        if(data.company){
          ReactSession.set("current_company", data.company);
          setCompany(data.company);
          loadLastPromo(data.company.id);

          if(data.company.websetting_galleries){
            setGalleries(data.company.websetting_galleries.filter((g) => g.category === 'home_app'));
            setGalleriesPromo(data.company.websetting_galleries.filter((g) => g.category === 'home_promo'));
          }

          loadCategories(data.company.id);
        }
        if(data.group_user_id > 0){
          setMitra(true)
        }

        var elem = document.querySelector(".slider1");
        var instance = window.M.Carousel.init(elem, {
          fullWidth: true,
          indicators: true,
        });

        var elem = document.querySelector(".slider2");
        var instance = window.M.Carousel.init(elem, {
          fullWidth: true,
          indicators: true,
        });
      })
      .catch(error => {
        setCurrentUser();
        ReactSession.remove('token')
        ReactSession.remove('current_user')

        let hostName = window.location.hostname;
        // let hostName = 'seemitra.id';
        hostName = hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
        
        const config = { params: {domain: hostName, include: 'company, websetting_galleries'} };
        API.get(`get_company`, config )
          .then(res => {
            const data = res.data.data;
            
            if(data){
              ReactSession.set("current_company", data);
              setCompany(data);
              loadLastPromo(data.id);
    
              if(data.websetting_galleries){
                setGalleries(data.websetting_galleries.filter((g) => g.category === 'home_app'));
                setGalleriesPromo(data.websetting_galleries.filter((g) => g.category === 'home_promo'));
              }

              loadCategories(data.id);
            }
    
            var elem = document.querySelector(".slider1");
            var instance = window.M.Carousel.init(elem, {
              fullWidth: true,
              indicators: true,
            });
    
            var elem = document.querySelector(".slider2");
            var instance = window.M.Carousel.init(elem, {
              fullWidth: true,
              indicators: true,
            });
          })

        // history.push("/");
        // if(error === undefined){
        //   alert.show('Gagal, Terjadi kesalahan di server')
        // }else{
        //   alert.show(error.response.data.meta.status.message);
        // }
        // console.log(error.response);
      })
  }

  const loadCategories = (company_id) => {
    const config_opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: company_id, pagination: false, by_status: true, active_promo: true } };
    API.get(`categories`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCategories(data)
      })
  }

  const loadLastPromo = (company_id) => {
    const config_opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: company_id, pagination: false, by_status: true } };

    API.get(`last_promo`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setPromo(data)
      })
  }

  const claimSaldo = () => {
    setSpinner(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const data = {
      category: 'out',
      virtual_money: virtualMoney,
      payment_number: paymentNumber
    }
    
    API.post(`claim_moneys`, data, config )
      .then(res => {
        const data = res.data.data;
        loadData();
        setSpinner(false);
        setOpenModal(false);
        alert.success('Pengajual Berhasil, Tim Kami Akan Memproses Pengajuan Pencairan Dana Anda')
      })
      .catch(error => {
        setSpinner(false);
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }
  
  useEffect(() => {
    // if(token === undefined){
    //   history.push("/")
    // }

    loadData();
    M.AutoInit();
    
  },[])

  return (
    company ? (
      <>
        <Helmet>
          <title>{company ? company.full_name : 'Mobile App'}</title>
          <meta name="description" content="" />
          <link rel="apple-touch-icon" href={company.company_icon_url} />
          <link rel="icon" href={company.company_icon_url} />
        </Helmet>
        
        <Header title='Header Loop'/>

        { galleries && galleries.length > 0 ? (
          <div className="row bg-grey m-bottom0">
            <div className="slider1 carousel carousel-slider carousel-home">
              {galleries.map((d, i) => 
                <div key={i} className={i === 0 ? `carousel-item active` : `carousel-item`}><img src={d.image_url}/></div>
              )}
            </div>
          </div>
        ) : (
          <div className="row bg-grey m-bottom0">
            <div className="carousel-slider carousel-home blank-carousel" style={{background: (company.company_color2)}}>
            </div>
          </div>
        ) }

        { openModal && currentUser ? (
          <div id="modal1" className='modal modal-visible'>
            <div className='modal-header'>
              Ajukan Pencairan Saldo <i className="material-icons modal-close"  onClick={(e) => setOpenModal(false)}>close</i>
            </div>
            <div className='modal-content'>
              <div className="col s12">
                <label className='m-bottom10'>
                  Masukan Nominal
                </label>
                <input className="input-back" value={virtualMoney} onChange={e => setVirtualMoney(e.target.value)} />
              </div>
              <div className="col s12 m-bottom0 m-top0 p-top0 select-none-ui">
                <label className='m-bottom10'>Transfer Ke Rekening</label>
                <select className='select' value={paymentNumber} onChange={e => setPaymentNumber(e.target.value)} >
                  <option value={currentUser.payment_bank_name+'-'+currentUser.payment_bank_number}>
                    {currentUser.payment_bank_name+'-'+currentUser.payment_bank_number}
                  </option>
                </select>
              </div>
              <div className="col s12">
                { spinner ? (
                  <button className="btn waves-effect waves-light btn-large full-width m-top-10" style={{background: (company.company_color3)}} type="submit" name="action" >
                    <center><Dots color='#fff' /></center>
                  </button>
                ) : (
                  <button className="btn waves-effect waves-light btn-large full-width m-top-10" style={{background: (company.company_color3)}} type="submit" name="action" onClick={(e) => claimSaldo()} >
                    Ajukan Sekarang
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : null}

        { currentUser ? (
          <div className="row bg-grey m-bottom0">
            <div className='col col s12 m12 bg-col-acumulation'>
              <div className="wellcome">
                Hi! {currentUser.full_name}
              </div>
              <div className='bg-acumulation-container'>
                <div className="bg-acumulation-home">
                  <div className='col col s2 m2 p8'>
                    <div className='acumulation-home'>
                      <div className='acumulation-input pointer' onClick={(e) => setOpenModal(true)} >
                        { company && company.username.includes('seemitra') ? (
                          <i className="material-icons" style={{color: (company.company_color1)}}>account_balance_wallet</i>
                        ) : (
                          <i className="material-icons" style={{color: (company.company_color1)}}>payment</i>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col col s5 m5 p8'>
                    <div className='acumulation-home pointer' onClick={e => history.replace(`/history_moneys`)}>
                      <div className='acumulation-home-total'>
                        {/* <i className="material-icons" style={{color: (company.company_color1)}}>credit_card</i>  */}
                        <b>{currentUser.virtual_money ? numberFormat(currentUser.virtual_money) : numberFormat(0)}</b>
                      </div>
                      <span className='small'>Saldo Saya</span>
                    </div>
                  </div>
                  <div className='col col s5 m5 p8'>
                    <div className='acumulation-home pointer' onClick={e => history.replace(`/history_points`)}>
                      <div className='acumulation-home-total'>
                        {/* <i className="material-icons" style={{color: (company.company_color1)}}>stars</i>   */}
                        <b>{currentUser.virtual_point ? numberFormat(currentUser.virtual_point) : numberFormat(0)}</b>
                      </div>
                      <span className='small'>Point Saya</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row bg-grey m-bottom0">
            <div className='col col s12 m12 bg-col-acumulation'>
              <div className="wellcome">
                Selamat Datang di {company.full_name} !
              </div>
              <div className='bg-acumulation-container'>
                <div className="bg-acumulation-home">
                  <div className='col col s6 m6 p8'>
                    <div className='acumulation-home pointer' onClick={e => history.replace(`/login`)}>
                      <div className='acumulation-home-total'>
                        <Link className='a-device' to="/login">LOG IN</Link>
                      </div>
                    </div>
                  </div>
                  <div className='col col s6 m6 p8'>
                    <div className='acumulation-home pointer' onClick={e => history.replace(`/register`)}>
                      <div className='acumulation-home-total'>
                        <Link className='a-device' to="/register">DAFTAR</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) }
      
        { mitra && currentUser ? (
          <div className="row m-bottom0">
            <div className="col s12 m12 padding0">
              <div className="icon-apps" onClick={e => history.replace(`/reports`)}>
                <div className="icon-app">
                  { company && company.username.includes('seemitra') ? (
                    <img src={ReportIcon} />
                  ) : (
                    <i class="material-icons dp48" style={{color: (company.company_color1)}}>insert_chart</i>
                  )}
                </div>
                <div className='icon-app-name'>Report</div>
              </div>
              <div className="icon-apps" onClick={e => history.replace(`/payments`)}>
                <div className="icon-app">
                  { company && company.username.includes('seemitra') ? (
                    <img src={PointIcon} />
                  ) : (
                    <i class="material-icons dp48" style={{color: (company.company_color1)}}>exit_to_app</i>
                  )}
                </div>
                <div className='icon-app-name'>Pembayaran</div>
              </div>
              <div className="icon-apps" onClick={e => history.replace(`/rewards`)}>
                <div className="icon-app">
                  { company && company.username.includes('seemitra') ? (
                    <img src={MoneyIcon} />
                  ) : (
                    <i class="material-icons dp48" style={{color: (company.company_color1)}}>stars</i>
                  )}
                </div>
                <div className='icon-app-name'>Reward</div>
              </div>
              <div className="icon-apps" onClick={e => history.replace(`/blogs`)}>
                <div className="icon-app">
                  { company && company.username.includes('seemitra') ? (
                    <img src={InvoiceIcon} />
                  ) : (
                    <i class="material-icons dp48" style={{color: (company.company_color1)}}>ondemand_video</i>
                  )}
                </div>
                <div className='icon-app-name'>
                  { company && company.username.includes('seemitra') ? 'Mentoring' : 'Blog' }
                </div>
              </div>
              <div className="icon-apps" onClick={e => history.replace(`/landing_pages`)}>
                <div className="icon-app">
                  { company && company.username.includes('seemitra') ? (
                    <img src={BlogIcon} />
                  ) : (
                    <i class="material-icons dp48" style={{color: (company.company_color1)}}>language</i>
                  )}
                </div>
                <div className='icon-app-name'>Website</div>
              </div>
              <div className="icon-apps" onClick={e => history.replace(`/content_kit`)}>
                <div className="icon-app">
                  { company && company.username.includes('seemitra') ? (
                    <img src={DownloadIcon} />
                  ) : (
                    <i class="material-icons dp48" style={{color: (company.company_color1)}}>photo_camera</i>
                  )}
                </div>
                <div className='icon-app-name'>Konten Kit</div>
              </div>
              <div className="icon-apps" onClick={e => history.replace(`/track_order`)}>
                <div className="icon-app">
                  { company && company.username.includes('seemitra') ? (
                    <img src={ConfirmationIcon} />
                  ) : (
                    <i class="material-icons dp48" style={{color: (company.company_color1)}}>find_in_page</i>
                  )}
                </div>
                <div className='icon-app-name'>Track Resi</div>
              </div>
              <div className="icon-apps" onClick={e => history.replace(`/check_shipping`)}>
                <div className="icon-app">
                  { company && company.username.includes('seemitra') ? (
                    <img src={ProspectIcon} />
                  ) : (
                    <i class="material-icons dp48" style={{color: (company.company_color1)}}>local_shipping</i>
                  )}
                </div>
                <div className='icon-app-name'>Cek Ongkir</div>
              </div>
            </div>
          </div>
        ) : currentUser && company && company.username.includes('seemitra') ? (
          <div className="row m-bottom0">
            <div className="col s12 m12 padding0">
              <div className="icon-apps" onClick={e => history.replace(`/rewards`)}>
                <div className="icon-app">
                  { company && company.username.includes('seemitra') ? (
                    <img src={MoneyIcon} />
                  ) : (
                    <i class="material-icons dp48" style={{color: (company.company_color1)}}>stars</i>
                  )}
                </div>
                <div className='icon-app-name'>Reward</div>
              </div>
              <div className="icon-apps" onClick={e => history.replace(`/content_kit`)}>
                <div className="icon-app">
                  { company && company.username.includes('seemitra') ? (
                    <img src={DownloadIcon} />
                  ) : (
                    <i class="material-icons dp48" style={{color: (company.company_color1)}}>photo_camera</i>
                  )}
                </div>
                <div className='icon-app-name'>Konten Kit</div>
              </div>
              <div className="icon-apps" onClick={e => history.replace(`/track_order`)}>
                <div className="icon-app">
                  { company && company.username.includes('seemitra') ? (
                    <img src={ConfirmationIcon} />
                  ) : (
                    <i class="material-icons dp48" style={{color: (company.company_color1)}}>find_in_page</i>
                  )}
                </div>
                <div className='icon-app-name'>Track Resi</div>
              </div>
              <div className="icon-apps" onClick={e => history.replace(`/check_shipping`)}>
                <div className="icon-app">
                  { company && company.username.includes('seemitra') ? (
                    <img src={ProspectIcon} />
                  ) : (
                    <i class="material-icons dp48" style={{color: (company.company_color1)}}>local_shipping</i>
                  )}
                </div>
                <div className='icon-app-name'>Cek Ongkir</div>
              </div>
            </div>
          </div>
        ) : null }

        { currentUser && company && company.username.includes('seemitra') ? (
          <div className="row box-profile-info" style={{marginBottom:0,marginTop:0,paddingTop:0}}>
            <div className='col col s12'>
              <div className='box-contact-cs'>
                <div className='row m-bottom0'>
                  <div className='col col s3 m3'>
                    <div className='acumulation-home'>
                      <div className='acumulation-input pointer avatar-am'>
                        <i className="material-icons" style={{color: (company.company_color1)}}>assignment_ind</i>
                      </div>
                    </div>
                  </div>
                  <div className='col col s6 m6'>
                    <div style={{fontSize: 15, marginTop: 5}}>
                      <b>{currentUser.staff_name ? currentUser.staff_name : 'CS Admin'}</b>
                    </div>
                    <div>
                      <span>Hubungi CS Manager Anda</span>
                    </div>
                  </div>
                  <div className='col col s3 m3'>
                    <a style={{marginTop: 10, background: (company.company_color2)}} href={'https://api.whatsapp.com/send?phone='+(currentUser.staff_phone ? currentUser.staff_phone : company.phone1)+'&text=Saya%20reseller%20seemitra%20mau%20konsultasi?'} className='btn btn-chat-am'>Chat</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        { company && promo && promo.id ? (
          <ProductSection token={token} title={promo.title} currentUser={currentUser} currentCompany={company} promo={promo} promo_id={promo.id}/>
        ) : null}


        { categories ? (
          <div className="row p-top-10 back-grey" style={{marginBottom: 0}}>
            <div className="col s12 m12" style={{padding: 0}}>
              <div className='scroll-touch'>
                <div className='scroll-container'>

                  {
                    categories.map((p) => {
                      return (
                        <Link to={'/products?category='+p.id} className="icon-categories">
                          <div className="icon-image" style={{borderRadius:0}}>
                            <img src={p.image_url}/>
                          </div>
                          <div className='icon-category-name'>{p.name}</div>
                        </Link>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        ) : null}

        { galleriesPromo && galleriesPromo.length > 0 ? (
          <div className="row m-bottom0" style={{padding: 10, paddingBottom: 0}}>
            <div className="slider2 carousel carousel-slider carousel-home" style={{borderRadius: 8}}>
              {galleriesPromo.map((d, i) => 
                <div key={i} className={i === 0 ? `carousel-item active` : `carousel-item`}><img src={d.image_url}/></div>
              )}
            </div>
          </div>
        ) : null }

        { company ? (
          <>
            <ProductSection token={token} title='Produk Promo' currentUser={currentUser} currentCompany={company}/>
          </>
        ) : null }

        <ProductSection token={token} title='Produk Terlaris' currentUser={currentUser} currentCompany={company}/>
        
        { currentUser && (company && company.username.includes('seemitra') === false) ? (
          <div className="row box-profile-info" style={{marginBottom:0,marginTop:0,paddingTop:0}}>
            <div className='col col s12'>
              <div className='box-contact-cs'>
                <div className='row m-bottom0'>
                  <div className='col col s3 m3'>
                    <div className='acumulation-home'>
                      <div className='acumulation-input pointer avatar-am'>
                        <i className="material-icons" style={{color: (company.company_color1)}}>assignment_ind</i>
                      </div>
                    </div>
                  </div>
                  <div className='col col s6 m6'>
                    <div style={{fontSize: 15, marginTop: 5}}>
                      <b>{currentUser.staff_name ? currentUser.staff_name : 'CS Admin'}</b>
                    </div>
                    <div>
                      <span>Hubungi CS Anda</span>
                    </div>
                  </div>
                  <div className='col col s3 m3'>
                    <a style={{marginTop: 10, background: (company.company_color2)}} href={'https://api.whatsapp.com/send?phone='+(currentUser.staff_phone ? currentUser.staff_phone : company.phone1)+'&text=Saya%20reseller%20seemitra%20mau%20konsultasi?'} className='btn btn-chat-am'>Chat</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        { company && company.username.includes('seemitra') ? (
          <>
            { currentUser ? (
              <>
                { company && company.group_facebook && company.group_facebook.length > 5 || company && company.group_telegram && company.group_telegram.length > 5 ? (
                  <>
                    <div className="row bg-white m-bottom0 custom">
                      <div className="col-12 custom" style={{paddingTop: 20, paddingBottom: 20}}>
                        <center>
                          <div className='title-card-content' style={{marginBottom:10}}>Gabung Komunitas Group</div>
                          {
                            company.group_facebook.length > 5 ? (<a href={company.group_facebook} className='icon-social fb'>Group Facebook</a>) : null
                          }
                          {
                            company.group_telegram.length > 5 ? (<a href={company.group_telegram} className='icon-social tl'>Group Telegram</a>) : null
                          }
                        </center>
                      </div>
                    </div>
                  </>
                ): null }
              </>
            ) : null }

            <div className="row bg-white m-bottom0 custom">
              <div className="col-12 custom" style={{padding: 20}}>
                <center>
                  <div className='title-card-content' style={{marginBottom:10}}>Social Media Official</div>
                  <a href={'https://'+company.domain} className='icon-social web'>Website</a>
                  {company.facebook && company.facebook.length > 5 ? (<a href={company.facebook} className='icon-social fb'>Facebook</a>) : null }
                  {company.instagram && company.instagram.length > 5 ? (<a href={company.instagram} className='icon-social ig'>Instagram</a>) : null }
                  {company.youtube && company.youtube.length > 5 ? (<a href={company.youtube} className='icon-social yt'>Youtube</a>) : null }
                </center>
              </div>
            </div>
            
            { currentUser ? (
              <div className="row bg-white m-bottom0 custom">
                <div className="col-12 custom" style={{padding: 20}}>
                  <center>
                    <div className='title-card-content' style={{marginBottom:10}}>Alamat Kantor</div>
                    <p>{company.address_office}</p>
                    <br/>
                    <div className='title-card-content' style={{marginBottom:10}}>Alamat Gudang</div>
                    <p>{company.address}</p>
                  </center>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      
        <BottomSheet/>
      </>
    ) : null
  );
}

export default Dashboard;
