import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import ReactSession from '../ReactSession';

const ScreenLoading = () => {
  const currentCompany = ReactSession.get("current_company");
  return (
    <div className='screen-loading' style={{background: (currentCompany.company_color4)}} >
      <div className="load-icon" >
        <center>
          <img src={currentCompany.company_icon_url} className="loader-logo"/>
          <Spinner color='#fff' />
        </center>
      </div>
    </div>
  )
}

export default ScreenLoading