import React, { useEffect, useState} from 'react';
import ReactSession from '../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ScreenLoading from '../components/ScreenLoading';
import BottomSheet from '../components/BottomSheet';
import LinesEllipsis from 'react-lines-ellipsis';

const Rewards = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const history = useHistory();

  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [spinnerScreen, setSpinnerScreen] = useState(true);

  const loadData = (keyword) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, include: 'company', by_verified: true }};
    API.get(`rewards`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
  }

  const loadMoreData = () => {
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, key: '', include: 'company', by_verified: true } };
    
    API.get(`rewards`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }
      })
  }


  useEffect(() => {  
    if(token === undefined){
      history.push("/login")
    }

    loadData();
    setSpinnerScreen(false);  

  },[])

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed">
        <nav className="nav-screen" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/dashboard" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Reward</span>
          </div>
        </nav>
      </div>

      <div className='sidenav-overlay'></div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <>
          <div class='row m-bottom0'>
            <>
              { spinner ? (
                <div className='text-center'>
                  <div className='loadLoading'><Dots color='#444' /></div>
                </div>
              ) : (
                <>
                  { data && data.length > 0 ? (
                    <>
                      {
                        data.map((u) => {
                          return (
                            <div className="col s6 m6" key={u.id}>
                              <Link key={u.id} to={'/reward/'+u.id} className="card card-product vertical" style={{background: 'white'}}>
                                <div className="card-image" style={{height: 160}}>
                                  <img alt="..." src={u.image_url} />
                                </div>
                                <div className='card-content'>
                                  <div className='title-card'>
                                    <LinesEllipsis
                                      text={u.title}
                                      maxLine='2'
                                      ellipsis='...'
                                      trimRight
                                      basedOn='letters'
                                    />  
                                  </div>
                                  <div className='category-card'>
                                    Syarat POIN <b>{u.point}</b>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          )
                        })
                      }
                    </>
                    ) : (
                      <div className='blank-message'>
                        Data Tidak Ditemukan
                      </div>
                    )
                  }
                </>
              )}
              {paging ? (
                <div onClick={loadMoreData} class='load-more-btn-white' style={{display: 'inline-block'}}>
                  {loadMore ? (
                    <center><Dots color='#444' /><br/></center>
                  ) : null}
                  SELANJUTNYA
                </div>
              ) : null}
            </>
          </div>

        </>
      )}
      <BottomSheet/>
    </>
    ) : null
  );
}

export default Rewards;
