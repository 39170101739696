import React, { useEffect, useState} from 'react';
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css'
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ScreenLoading from '../components/ScreenLoading';
import { useAlert } from 'react-alert';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function NewlineText(props) {
  const text = props.text;
  return text.split('\n').map(str => <p>{str}</p>);
}

const RewardShow = () => {
  const {id} = useParams();

  const currentCompany = ReactSession.get("current_company");
  const alert = useAlert();
  const history = useHistory();
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");

  const [data, setData] = useState({});
  const [spinnerScreen, setSpinnerScreen] = useState(true);
  const [spinner, setSpinner] = useState(false);

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {} };

    API.get(`rewards/${id}`, config)
      .then(res => {
        setSpinnerScreen(false);
        const data = res.data.data;
        setData(data);
      })
  }
  
  useEffect(() => {
    if(token === undefined){
      history.push("/login")
    }

    loadData();
    
    // let elSide = document.querySelectorAll('.sidenav-overlay');
    // let elBody = document.querySelectorAll('body');
    // elSide.forEach((item: any) => {
    //   item.style.display = 'none';
    // })
    // elBody.forEach((item: any) => {
    //   item.style.overflow = 'auto';
    // })
  
  },[])

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed navbar-fixed-50">
        <nav className="nav-screen" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/rewards" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Detail Reward</span>
          </div>
        </nav>
      </div>

      <div className='sidenav-overlay'></div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <>
          <div className="row m-bottom-0 back-grey">
            <img src={data.image_url} style={{width: '100%'}}/>
          </div>
          
          <div className="row border-bottom back-white">
            <div className="col s12 m12 p-bottom-0">
              <div className='title-card-content'>{data.title}</div>
              <div className='title-card-price' style={{color: (currentCompany ? currentCompany.company_color2 : '')}}>
                POIN {data.point}
              </div>
            </div>
          </div>

          <div className="row border-bottom back-white">
            <div className="col s12 m12">
              <div className='title-card-desc'>
                { data && data.description ? (
                  <NewlineText text={data.description} />
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
    </>
    ) : null
  );
}

export default RewardShow;
