import React, { useEffect, useState} from 'react';
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';
import { useAlert } from 'react-alert';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import BottomSheet from '../components/BottomSheet';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const CheckShipping = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const history = useHistory();

  const [districts, setDistricts] = useState([]);
  const [data, setData] = useState([]);
  const [originId, setOriginId] = useState('');
  const [districtId, setDistrictId] = useState('');
  const [weight, setWeight] = useState(0);

  const [spinner, setSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Data Ongkir Belum Ditelusuri');
  const [spinnerScreen, setSpinnerScreen] = useState(true);

  const handleOnSearch = (string, results) => {
    console.log(string, results)
    findDistrict(string)
  }

  const handleOnHover = (result) => {
    console.log(result)
  }

  const handleOnSelect = (item) => {
    setOriginId(item.id)
  }

  const handleOnSelect2 = (item) => {
    setDistrictId(item.id)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return item;
   // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  }

  const findDistrict = (query) => {
    API.get(`regions`, {params: {key: query}} )
      .then(res => {
        const data = res.data.data;
        setDistricts(data);
      })
  }

  const checkPost = () => {    
    setSpinner(true);
    setErrorMessage(null);
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { origin_id: originId, district_id: districtId, weight: parseInt(weight) } };

    API.get(`check_shipping_price`, config)
      .then(res => {
        setSpinner(false);
        const data = res.data.report;
        setData(data);
      })
      .catch(error => {
        setSpinner(false);
        setData([]);
        if(error === undefined){
          setErrorMessage('Ongkir Tidak Dapat Ditelusuri');
        }else{
          setErrorMessage('Ongkir Tidak Dapat Ditelusuri');
        }
        console.log(error.response);
      })
  }


  useEffect(() => {  
    if(token === undefined){
      history.push("/login")
    }

    setSpinnerScreen(false);  
  
  },[])

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed">
        <nav className="nav-screen" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/dashboard" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Cek Ongkos Kirim</span>
          </div>
        </nav>
      </div>

      <div className='sidenav-overlay'></div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <>
          <div className="card horizontal card-cart border-none">
            <div class='row m-bottom0'>
              <div className="col s12 autocomplete-field">
                <label className='m-bottom10'>Kecamatan Asal Pengiriman</label>
                <ReactSearchAutocomplete
                  items={districts}
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  // formatResult={formatResult}
                  />
              </div>
              <div className="col s12">
                <label className='m-bottom10'>Masukan Berat ( Satuan Gram )</label>
                <input placeholder="Berat Satua Gr" type="text" className="input-back" value={weight} onChange={e => setWeight(e.target.value)} />
              </div>
              <div className="col s12 autocomplete-field">
                <label className='m-bottom10'>Kecamatan Tujuan Pengiriman</label>
                <ReactSearchAutocomplete
                  items={districts}
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect2}
                  // formatResult={formatResult}
                  />
              </div>
              <div className='col col s12 m12'>
                <button onClick={(e) => checkPost(e)} className='btn waves-effect waves-light btn-large full-width' style={{background: (currentCompany.company_color3)}}>CEK ONGKIR</button>
              </div>
            </div>
          </div>

          <div className="card horizontal card-cart border-none">
            <div class='row m-bottom0'>
              <div className="col s12">
                { spinner ? (
                  <div className='text-center'>
                    <div className='loadLoading'><Dots color='#444' /></div>
                  </div>
                ) : (
                  <>
                    {
                      data.map((u) => {
                        return (
                          <div className='list-content' key={u.id}>
                            <div>
                              <i className="ni ni-delivery-fast text-blue"></i> {u.name} {u.service} ({u.description})
                              <br/>
                              Ongkir <b>{u.price}</b> Estimasi Hari <b>{u.day}</b>
                            </div>
                          </div>
                        )
                      })
                    }
                  </>
                )}

                {
                  errorMessage ? (
                    <div className='text-center'>
                      <span>{errorMessage}</span>
                    </div>
                  ) : null
                }
              </div>
            </div>
          </div>

        </>
      )}
      <BottomSheet/>
    </>
    ) : null
  );
}

export default CheckShipping;
