import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert'
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import { DatePicker, Autocomplete } from "react-materialize";
import { format } from "date-fns";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { Helmet } from 'react-helmet';
import HeaderScreen from '../components/HeaderScreen';

const Register = () => {
  const alert = useAlert();
  const history = useHistory();

  const [company, setCompany] = useState({});
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [birthday, setBirthday] = useState('');
  const [addressCode, setAddressCode] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [domain, setDomain] = useState('');
  const [referralCode, setReferralCode] = useState('');

  const [queryDistrict, setQueryDistrict] = useState('');
  const [districts, setDistricts] = useState([]);

  const [spinner, setSpinner] = useState(false);

  const loadData = () => {
    let hostName = window.location.hostname;
    hostName = hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    setDomain(hostName);

    const config = { params: {domain: hostName} };
    API.get(`get_company`, config )
      .then(res => {
        const data = res.data.data;
        setCompany(data);
      })
  }

  const actPost = () => {
    ReactSession.remove("register_member");
    
    setSpinner(true);
    const data = {
      full_name: fullName, 
      username: username, 
      email: email, 
      phone1: phone, 
      gender: gender, 
      birthday: birthday, 
      address_code: addressCode, 
      address: address, 
      password: password,
      domain: domain,
      reff_code: referralCode
    }

    API.post('register', data )
      .then(res => {
        ReactSession.set("register_member", res.data.data);
        setSpinner(false);
        history.push("/welcome");
      })
      .catch(error => {
        setSpinner(false);
        if(error === undefined){
          alert.show('Pendaftaran Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const handleOnSearch = (string, results) => {
    console.log(string, results)
    findDistrict(string)
  }

  const handleOnHover = (result) => {
    console.log(result)
  }

  const handleOnSelect = (item) => {
    setAddressCode(item.id)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return item;
   // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  }

  const findDistrict = (query) => {
    setQueryDistrict(query);
    console.log('find district '+query)
    API.get(`regions`, {params: {key: query}} )
      .then(res => {
        const data = res.data.data;
        setDistricts(data);
        console.log(data)
      })
  }

  useEffect(() => {
    loadData('');
  },[])

  return (
    <>
      { company ? (
        <Helmet>
          <title>{company ? company.full_name : 'Mobile App'}</title>
          <meta name="description" content="" />
          <link rel="apple-touch-icon" href={company.company_icon_url} />
          <link rel="icon" href={company.company_icon_url} />
        </Helmet>
      ) : null}

      <HeaderScreen title="Daftar"/>
      <div className="row login-form">
        <div className="input-field col s12">
          { company ? (
            <a href={`https://m.${company.domain}`}>
              <img src={company.company_logo_url} className="login-logo"/>
            </a>
          ) : (
            <h1 className='h1-login'>Register</h1>
          ) }
        </div>
        <div className="col s12">
          <div className="m-bottom10">Kode Referral (Optional)</div>
          <input placeholder='Kode Referral' type="text" className="validate input-back" value={referralCode} onChange={e => setReferralCode(e.target.value)} />
        </div>
        <div className="col s6">
          <div className="m-bottom10">Nama Lengkap</div>
          <input placeholder='Nama Lengkap' type="text" className="validate input-back" value={fullName} onChange={e => setFullName(e.target.value)} />
        </div>
        <div className="col s6">
          <div className="m-bottom10">Username</div>
          <input placeholder='Username' type="text" className="validate input-back" value={username} onChange={e => setUsername(e.target.value)} />
        </div>
        <div className="col s6">
          <div className="m-bottom10">No.HP/WA Aktif</div>
          <input placeholder='+62 ...' type="text" className="validate input-back" value={phone} onChange={e => setPhone(e.target.value)} />
        </div>
        <div className="col s6">
          <div className="m-bottom10">Alamat Email</div>
          <input placeholder='name@example.com' type="text" className="validate input-back" value={email} onChange={e => setEmail(e.target.value)} />
        </div>
        <div className="col s6 select-none-ui">
          <div className="m-bottom10">Jenis Kelamin</div>
          <select className="select" value={gender} onChange={e => setGender(e.target.value)} >
            <option value="">Pilih</option>
            <option value="Laki-Laki">Laki-Laki</option>
            <option value="Perempuan">Perempuan</option>
          </select>
        </div>
        <div className="col s6 datepicker-field">
          <div className="m-bottom10">Tanggal Lahir</div>
          <DatePicker options={{ yearRange: 70}} value={birthday} onChange={e => setBirthday( format(e, "yyyy-MM-dd") )}/>
        </div>
        <div className="col s12 autocomplete-field">
          <div className="m-bottom10">Kecamatan Domisili</div>
          <ReactSearchAutocomplete
            items={districts}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            // formatResult={formatResult}
            />
        </div>
        <div className="col s12">
          <div className="m-bottom10">Alamat Lengkap</div>
          <input placeholder="Alamat Lengkap" type="text" className="input-back" value={address} onChange={e => setAddress(e.target.value)} />
        </div>
        <div className="col s12">
          <div className="m-bottom10">Kata Sandi</div>
          <input placeholder='Kata Sandi' type="password" className="validate input-back" value={password} onChange={e => setPassword(e.target.value)} />
        </div>
        <div className="col s12">
          { spinner ? (
            <button className="btn waves-effect waves-light btn-large full-width" style={{background: (company ? company.company_color3 : '')}} type="submit" name="action" >
              <center><Dots color='#fff' /></center>
            </button>
          ) : (
            <button className="btn waves-effect waves-light btn-large full-width" style={{background: (company ? company.company_color3 : '')}} type="submit" name="action" onClick={actPost} >
              Daftar Sekarang
            </button>
          ) }
        </div>
        <div className="col s12">
          <center>
            <span>Anda Sudah Punya Akun ? <Link to="/" >Masuk Sekarang</Link></span>
            <br/>
            <span>Lupa Kata Sandi ? <Link to="/forgot_password" >Pulihkan Kata Sandi</Link></span>
          </center>
        </div>
      </div>
    </>
  )
}

export default Register
